import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { RestService } from 'src/app/utils/services/rest.service';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Musical_Genre } from 'src/app/models/musical_genre';
declare var $: any;

@Component({
  selector: 'app-musical_genres',
  templateUrl: './musical_genres.component.html',
  styleUrls: ['./musical_genres.component.css']
})
export class MusicalGenresComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = true;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  form: FormGroup;
  submitted = false;

  musical_genres: Musical_Genre[];
  isUpdate: boolean;

  constructor(private rest: RestService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [1] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = new FormGroup({
      genre_id: new FormControl(null),
      name: new FormControl(null, Validators.required),
    });

    this.musicalGenresList();
  }

  get f() {
    return this.form.controls;
  }

  /**
  * Lista dei generi musicali
  */
   musicalGenresList() {
    this.isLoading = true;

    this.rest.musicalGenresList().subscribe(res => {
      this.isLoading = false;
      this.musical_genres = res.data.musical_genres;

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Popup aggiunta/modifica città
   */
  edit(item: Musical_Genre) {
    this.submitted = false;

    if (item) {
      this.f.genre_id.setValue(item.genre_id);
      this.f.name.setValue(item.name);
    } else {
      this.form.reset();
    }
    $("#modal-default").modal('show');
  }

  /**
   * Elimina
   */
  delete(item: Musical_Genre) {
    this.rest.musicalGenreDelete(item.genre_id).subscribe(res => {
      this.toastr.success('Genere musicale eliminato con successo!');
      this.musicalGenresList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova o assicurati che questa città non risulti utilizzata in qualche sezione!');
    });
  }

  /**
   * Salvataggio città
   */
  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    var genre_id = this.f.genre_id.value;
    var name = this.f.name.value;

    if (genre_id) {
      this.rest.musicalGenreEdit(genre_id, name).subscribe(res => {
        $("#modal-default").modal('hide');
        this.toastr.success('Città modificata con successo!');
        this.musicalGenresList();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.musicalGenreAdd(name).subscribe(res => {
        $("#modal-default").modal('hide');
        this.toastr.success('Città aggiunta con successo!');
        this.musicalGenresList();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }

  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}


