<section class="content-header">
    <div class="container-fluid">
        <h1>Città</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button *ngIf="this.auth.currentUserValue.role_id == 0"  class="btn btn-info mb-3" [routerLink]="['/cities/new']"><i class="fas fa-plus mr-1"></i> Aggiungi</button>

                <div class="card">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Città</th>
                                    <th>Nazione</th>
                                    <th style="width: 20%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of cities">
                                    <td>{{item.city_name}}</td>
                                    <td>{{item.country_name}}</td>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" [routerLink]="['/cities/detail', item.city_id]"><i class="fas fa-eye mr-1"></i> Visualizza</button>
                                        <button [disabled]="this.auth.currentUserValue.role_id == 1" class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questa città ?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <swal #toast [swalOptions]="alertOption"></swal> -->