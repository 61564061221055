import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupMember } from 'src/app/models/group-member';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-modal-group-users',
  templateUrl: './modal-group-users.component.html',
  styleUrls: ['./modal-group-users.component.css']
})
export class ModalGroupUsersComponent implements OnInit {
  @Input() user_id: number;

  form: FormGroup;
  users: GroupMember[];

  constructor(
    public activeModal: NgbActiveModal,
    private rest: RestService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      users: new FormControl()
    });

    this.rest.groupMembersAvailableList(this.user_id).subscribe(res => {
      this.users = res.data.users;
    });
  }

  get f() {
    return this.form.controls;
  }

  applyFilter() {
    var users = this.f.users.value;
    this.activeModal.close(users);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
