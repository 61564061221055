import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();

  email: String;
  role: String;

  constructor(    
    private router: Router,
    private authService: AuthenticationService) { }

  ngOnInit() {
    this.email = this.authService.currentUserValue.email;
    switch (this.authService.currentUserValue.role_id) {
      case 0: this.role = "Amministratore"; break;
      case 1: this.role = "Organizzatore"; break;
      case 2: this.role = "Comune"; break;
      case 3: this.role = "Gestore palchi"; break;
      default: this.role = "Altro"; break;
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
