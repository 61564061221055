import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { OwlDateTimeComponent, OwlDateTimeInputDirective } from 'ng-pick-datetime';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { EventTalentPerform } from 'src/app/models/event-talent-perform';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-talent-absences',
  templateUrl: './talent-absences.component.html',
  styleUrls: ['./talent-absences.component.css']
})
export class TalentAbsencesComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = true;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  events: EventTalentPerform[];
  isUpdate: boolean;

  form: FormGroup;
  submitted = false;
  date: string;

  constructor(
    private rest: RestService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.date = this.route.snapshot.queryParamMap.get('date');
    if (!this.date) this.date = moment().subtract(1, 'days').format('YYYY-MM-DD');
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [6, 8] }],
      // order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = new FormGroup({
      date: new FormControl(this.date, Validators.required),
    });

    this.eventsTalentsPerformList();
  }

  get f() {
    return this.form.controls;
  }

  /**
  * Lista degli eventi
  */
  eventsTalentsPerformList() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;

    var date = this.f.date.value;

    this.rest.eventsTalentsPerformList(date).subscribe(res => {
      this.isLoading = false;
      this.events = res.data.events;

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  absenceJustify(event: EventTalentPerform, justified_absence: number) {
    this.isLoading = true;

    this.rest.absencesJustifySet(event.user_id, event.event_id, justified_absence).subscribe(res => {
      this.isLoading = false;
      this.eventsTalentsPerformList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  absenceAdmonition(event: EventTalentPerform) {
    this.isLoading = true;

    this.rest.absencesAdmonitionSet(event.user_id, event.event_id).subscribe(res => {
      this.isLoading = false;
      this.eventsTalentsPerformList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}


