<form [formGroup]="form">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-12">
                <label>Stelle</label>
                <select formControlName="stars" class="form-control">
                    <option *ngFor="let d of stars; let i = index;" [ngValue]="d.id">
                        {{d.name}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
        <button type="submit" class="btn btn-info" (click)="apply()">Applica</button>
    </div>
</form>