import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-modal-talent-stars',
  templateUrl: './modal-talent-stars.component.html',
  styleUrls: ['./modal-talent-stars.component.css']
})
export class ModalTalentStarsComponent implements OnInit {
  @Input() user_stars: number;

  form: FormGroup;

  stars = [
    { id: 0, name: "0 stelle" },
    { id: 1, name: "1 stella" },
    { id: 2, name: "2 stelle" }
  ];

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      stars: new FormControl(this.user_stars),
    });
  }

  get f() {
    return this.form.controls;
  }

  apply() {
    this.activeModal.close(this.f.stars.value)
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
