import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { RestService } from 'src/app/utils/services/rest.service';
import { City } from 'src/app/models/city';
import { Place } from 'src/app/models/place';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.css']
})
export class PlacesComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = true;

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject();

  cities: City[] = [];
  places: Place[] = [];
  isUpdate: boolean;

  constructor(
    private rest: RestService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [8] }],
      pageLength: 100,
      order: [1, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      dom: "<'mb-3 text-center'B><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          text: 'Esporta Excel',
          action: () => {
            this.export();
          }
        }
      ]
    };

    this.citiesList();
    this.placesList();
  }

  /**
   * Lista dei luoghi
   */
  placesList() {
    this.isLoading = true;

    this.rest.placesList().subscribe(res => {
      this.isLoading = false;

      this.places = res.data.places;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
* Lista delle città
*/
  citiesList() {
    this.rest.citiesList().subscribe(res => {
      this.cities = res.data.cities;
    });
  }

  getCityName(city_id) {
    for (let city of this.cities) {
      if (city.city_id == city_id) {
        return city.city_name;
      }
    }
    return "";
  }

  export() {
    this.rest.placesExportExcel().subscribe(res => {
      this.rest.downloadFile(res, "Luoghi", "xlsx");
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
 * Elimina
 */
  delete(item: Place) {
    this.rest.placeDelete(item.place_id).subscribe(res => {
      this.toastr.success('Luogo eliminato con successo!');
      this.placesList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
