import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { RestService } from 'src/app/utils/services/rest.service';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { Sponsor } from 'src/app/models/sponsor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSponsorComponent } from 'src/app/modals/modal-sponsor/modal-sponsor.component';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.css']
})
export class SponsorsComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = true;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  form: FormGroup;
  submitted = false;

  sponsors: Sponsor[];
  isUpdate: boolean;

  constructor(private rest: RestService, private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [5] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.sponsorsList();
  }

  /**
   * Lista dei banner
   */
  sponsorsList() {
    this.isLoading = true;

    this.rest.sponsorsList().subscribe(res => {
      this.isLoading = false;

      this.sponsors = res.data.sponsors;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  add() {
    const modalRef = this.modalService.open(ModalSponsorComponent);
    modalRef.result.then((result) => {
      this.sponsorsList();
    }, (reason) => { });
  }

  edit(item: Sponsor) {
    const modalRef = this.modalService.open(ModalSponsorComponent);
    modalRef.componentInstance.sponsor = item;
    modalRef.result.then((result) => {
      this.sponsorsList();
    }, (reason) => { });
  }

  /**
   * Elimina
   */
  delete(item: Sponsor) {
    this.rest.sponsorDelete(item.sponsor_id).subscribe(res => {
      this.toastr.success('Sponsor eliminato con successo!');
      this.sponsorsList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova o assicurati che questa città non risulti utilizzata in qualche sezione!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}