<section class="content-header">
    <div class="container-fluid">
        <h1>Organizzatori</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button class="btn btn-info mb-3" (click)="edit(null)"><i class="fas fa-plus mr-1"></i>
                    Aggiungi</button>

                <div class="card">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Cognome</th>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Città assegnate</th>
                                    <th>Ruolo</th>
                                    <th style="width: 20%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of managers">
                                    <td>{{item.surname}}</td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.email}}</td>
                                    <td>
                                        <span *ngFor="let city of item.cities; let isLast=last">
                                            {{city.city_name}}{{isLast ? '' : ', '}}
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="item.role_id == 1" class="badge badge-pill badge-success text-uppercase">ORGANIZZATORE</span>
                                        <span *ngIf="item.role_id == 2" class="badge badge-pill badge-danger text-uppercase">COMUNE</span>
                                        <span *ngIf="item.role_id == 3" class="badge badge-pill badge-info text-uppercase">GESTORE PALCHI</span>
                                    </td>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="edit(item)"><i class="fas fa-eye mr-1"></i> Visualizza</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo organizzatore ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }" (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal add/edit -->
<div class="modal fade" id="modal-default" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="form">
                <div class="modal-body">
                    <input formControlName="manager_id" type="hidden">

                    <div class="mb-3">
                        <label>Nome</label>
                        <input formControlName="name" type="text" class="form-control" placeholder="Inserici il nome" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                        <div *ngIf="f.name.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                    </div>

                    <div class="mb-3">
                        <label>Cognome</label>
                        <input formControlName="surname" type="text" class="form-control" placeholder="Inserici il cognome" [ngClass]="{ 'is-invalid': submitted && f.surname.errors }" />
                        <div *ngIf="f.surname.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                    </div>

                    <div class="mb-3">
                        <label>Email</label>
                        <input formControlName="email" type="text" class="form-control" placeholder="Inserici una indirizzo email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="f.email.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        <div *ngIf="f.email.errors?.email" class="invalid-feedback">Indirizzo email non valido</div>
                    </div>

                    <div class="mb-3">
                        <label>Password</label>
                        <input #password formControlName="password" type="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="f.password.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        <div *ngIf="f.password.errors?.minlength" class="invalid-feedback">Lunghezza min. 8 caratteri
                        </div>
                        <div *ngIf="f.password.errors?.maxlength" class="invalid-feedback">Lunghezza max. 45 caratteri
                        </div>
                    </div>

                    <div>
                        <label>Ruolo</label>
                        <select formControlName="role_id" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.role_id.errors }">
                            <option [ngValue]="null" disabled>Scegli un ruolo</option>
                            <option [ngValue]="1">Organizzatore</option>
                            <option [ngValue]="2">Comune</option>
                            <option [ngValue]="3">Gestore palchi</option>
                        </select>
                        <div *ngIf="f.role_id.errors?.required" class="invalid-feedback">Campo
                            obbligatorio
                        </div>
                    </div>

                    <div class="mt-3" *ngIf="f.role_id.value">
                        <label>Assegna Città</label>
                        <div class="select2-info">
                            <ng-select formControlName="city" (change)="cityChange()" [items]="cities" bindLabel="city_name" bindValue="city_id" [clearable]="false" [multiple]="f.role_id.value == 1"
                           [ngClass]="{ 'is-invalid': submitted && f.city.errors }" [placeholder]="f.role_id.value == 1 ? 'Scegli una o più città' : 'Scegli una città'">
                            </ng-select>

                            <div *ngIf="f.city.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>
                    </div>

                    <div class="mt-3" *ngIf="f.role_id.value == 3">
                        <label>Assegna Palchi</label>
                        <div class="select2-info">
                            <ng-select formControlName="places" [items]="places" bindLabel="place_name" bindValue="place_id" [clearable]="false" [multiple]="true"
                           [ngClass]="{ 'is-invalid': submitted && f.places.errors }" [placeholder]="'Scegli uno o più palchi'">
                            </ng-select>

                            <div *ngIf="f.places.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>
                    </div>

                    <div class="mt-3">
                        <label>Ricezione email</label>
                        <select formControlName="notify_email_enabled" class="form-control">
                            <option [ngValue]="0">No</option>
                            <option [ngValue]="1">Si</option>
                        </select>
                    </div>
                </div>
                
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button type="submit" class="btn btn-info" (click)="save()">Salva</button>
                </div>
            </form>

        </div>
    </div>
</div>