<section class="content-header">
    <div class="container-fluid">
        <h1>Scheda Gruppo</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-3">
                <div class="card card-widget widget-user">
                    <div class="widget-user-header bg-warning">
                        <h3 class="widget-user-username">{{user?.username}}</h3>
                        <small class="widget-user-desc">{{user?.email}}</small>
                    </div>
                    <div class="widget-user-image">
                        <img class="img-circle elevation-2"
                            src="{{user?.profile_url ? user.profile_url:'assets/img/profile_placeholder.png'}}"
                            alt="User profile picture">
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-6 border-right">
                                <div class="description-block">
                                    <h5 class="description-header">{{user?.creation_date | date :'dd/MM/yyyy HH:mm'}}
                                    </h5>
                                    <span class="description-text">Data registrazione</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="description-block">
                                    <h5 class="description-header">#{{user?.identifier}}</h5>
                                    <span class="description-text">Cod. identificativo</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <form *ngIf="id && this.auth.isAdmin()" [formGroup]="form_access">
                    <div class="card card-secondary">
                        <div class="card-header text-uppercase">
                            Dati di accesso
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label for="email">Email</label>
                                        <input type="text" class="form-control" id="email" formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted_access && fa.email.errors }">
                                        <div *ngIf="fa.email.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="saveAccessData()"> <span
                                    *ngIf="loading_access" class="spinner-border spinner-border-sm mr-2"></span>
                                Salva</button>
                        </div>
                    </div>
                </form>

                <form *ngIf="id && (this.auth.isAdmin() || this.auth.isMunicipality())"
                    [formGroup]="form_additional_info">
                    <div class="card card-secondary">
                        <div class="card-header text-uppercase">
                            Informazioni aggiuntive
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label for="notes">Note</label>
                                        <textarea class="form-control" id="notes" formControlName="notes"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="saveAdditionalInfo()">Salva</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-9">
                <form [formGroup]="form">
                    <div class="card card-info">
                        <div class="card-header text-uppercase">
                            Profilo
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12 text-center">
                                        <ui-switch formControlName="enabled"
                                            [disabled]="this.auth.currentUserValue.role_id == 1"></ui-switch>
                                    </div>

                                    <div [hidden]="id" class="form-group col-12 col-lg-6">
                                        <label for="email">Email</label>
                                        <input type="text" class="form-control" id="email" formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <div *ngIf="f.email.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="phone">Telefono</label>
                                        <input type="text" class="form-control" id="phone" formControlName="phone"
                                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                        <div *ngIf="f.phone.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-12 col-lg-6">
                                    <label for="username">Nome d'arte</label>
                                    <input type="text" class="form-control" id="username" formControlName="username"
                                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                                    <div *ngIf="f.username.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Città di preferenza</label>
                                    <select formControlName="city_id" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }">
                                        <option [ngValue]="null" disabled>Scegli una Città</option>
                                        <option *ngFor="let d of cities; let i = index;" [ngValue]="d.city_id">
                                            {{d.city_name}}
                                        </option>
                                    </select>
                                    <div *ngIf="f.city_id.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label for="score">Punti</label>
                                    <input type="number" class="form-control" id="score" formControlName="score"
                                        [ngClass]="{ 'is-invalid': submitted && f.score.errors }">
                                    <div *ngIf="f.score.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>

                                <div *ngIf="this.auth.isAdmin()" class="form-group col-12 col-lg-6">
                                    <label>Stelle</label>
                                    <select formControlName="stars" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.stars.errors }">
                                        <option *ngFor="let d of stars; let i = index;" [ngValue]="d.id">
                                            {{d.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="f.stars.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Premium</label>
                                    <select formControlName="premium" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Visualizza banner in home page</label>
                                    <select formControlName="home_banner_visible" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Profilo pubblico</label>
                                    <select formControlName="public_profile" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Email Verificata</label>
                                    <select formControlName="email_verified" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Lingua comunicazioni notifiche push e email</label>
                                    <select formControlName="language" class="form-control">
                                        <option [ngValue]="0">Italiano</option>
                                        <option [ngValue]="1">Inglese</option>
                                        <option [ngValue]="2">Portoghese</option>
                                    </select>
                                </div>

                                <div class="form-group col-12">
                                    <label for="bio">Biografia <small>(opzionale)</small></label>
                                    <textarea class="form-control" id="bio" formControlName="bio"></textarea>
                                </div>
                            </div>

                            <div>
                                <div class="text-center">
                                    <span class="badge badge-warning text-uppercase">Profili social</span>
                                </div>

                                <div class="row mt-2">
                                    <div class="form-group col-12 col-lg-6">
                                        <label for="spotify_url">Link Spotify <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="spotify_url"
                                                formControlName="spotify_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.spotify_url.value}"
                                                        href="{{f.spotify_url.value}}" target="_blank"><i
                                                            class="fab fa-spotify"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="soundcloud_url">Link Soundcloud <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="soundcloud_url"
                                                formControlName="soundcloud_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.soundcloud_url.value}"
                                                        href="{{f.soundcloud_url.value}}" target="_blank"><i
                                                            class="fab fa-soundcloud"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="instagram_url">Link Instagram <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="instagram_url"
                                                formControlName="instagram_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.instagram_url.value}"
                                                        href="{{f.instagram_url.value}}" target="_blank"><i
                                                            class="fab fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="x_url">Link X <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="x_url" formControlName="x_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.x_url.value}" href="{{f.x_url.value}}"
                                                        target="_blank"><i class="fab fa-twitter"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="tiktok_url">Link TikTok <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="tiktok_url"
                                                formControlName="tiktok_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.tiktok_url.value}"
                                                        href="{{f.tiktok_url.value}}" target="_blank"><i
                                                            class="fab fa-tiktok"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="youtube_url">Link Youtube <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="youtube_url"
                                                formControlName="youtube_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.youtube_url.value}"
                                                        href="{{f.youtube_url.value}}" target="_blank"><i
                                                            class="fab fa-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="facebook_url">Link Facebook <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="facebook_url"
                                                formControlName="facebook_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.facebook_url.value}"
                                                        href="{{f.facebook_url.value}}" target="_blank"><i
                                                            class="fab fa-facebook"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="link_url">Altro link <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="link_url"
                                                formControlName="link_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.link_url.value}"
                                                        href="{{f.link_url.value}}" target="_blank"><i
                                                            class="fa fa-link"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="donation_url">Link Donazione <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="donation_url"
                                                formControlName="donation_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.donation_url.value}"
                                                        href="{{f.donation_url.value}}" target="_blank"><i
                                                            class="fa fa-heart"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div *ngIf="this.auth.isAdmin() || this.auth.isMunicipality()" class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()"> <span *ngIf="loading"
                                    class="spinner-border spinner-border-sm mr-2"></span>
                                Salva</button>

                            <button *ngIf="id" [disabled]="!this.auth.isAdmin()" class="btn btn-danger btn-info"
                                [swal]="deleteSwal"><i class="fas fa-trash mr-2"></i>Elimina</button>
                            <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo gruppo ?" icon="warning"
                                [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                (confirm)="deleteTalent()"></swal>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-12">
                <div *ngIf="id" class="card card-info collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Città in cui il gruppo può esibirsi
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <button class="btn btn-info mb-3" (click)="addDocumentCity()"><i
                                class="fas fa-plus mr-1"></i>Aggiungi</button>

                        <table id="table_documents" datatable [dtOptions]="dtOptionsDocuments"
                            [dtTrigger]="dtTriggerDocuments" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th style="width: 60%">Città</th>
                                    <th style="width: 15%">Concessione</th>
                                    <th style="width: 25%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of documents">
                                    <td>{{item.city_name}}</td>
                                    <td [ngSwitch]="item.document_status">
                                        <span *ngSwitchCase="0" class="badge badge-pill badge-warning text-uppercase">In
                                            fase di revisione</span>
                                        <span *ngSwitchCase="1"
                                            class="badge badge-pill badge-success text-uppercase">Approvata</span>
                                        <span *ngSwitchCase="2"
                                            class="badge badge-pill badge-danger text-uppercase">Rifiutata</span>
                                    </td>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm"
                                            [routerLink]="['/groups/detail', id, 'city', item.city_id]"><i
                                                class="fas fa-eye mr-2"></i>Gestione</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteDocumentCitySwal"><i
                                                class="fas fa-trash mr-2"></i>Elimina</button>
                                        <swal #deleteDocumentCitySwal title="Elimina"
                                            text="Vuoi eliminare questa città ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deleteDocumentCity(item.city_id)"></swal>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingDocuments" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id" class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Ammonizioni
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <button class="btn btn-info mb-3" (click)="addAdmonition()"><i
                                class="fas fa-plus mr-1"></i>Aggiungi</button>

                        <table id="table_admonitions" datatable [dtOptions]="dtOptionsAdmonitions"
                            [dtTrigger]="dtTriggerAdmonitions" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Data ammonizione</th>
                                    <th>Data evento</th>
                                    <th>Evento</th>
                                    <th>Note</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of admonitions">
                                    <td>{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{(item.event_date != null) ? (item.event_date * 1000 | date :'dd/MM/yyyy
                                        HH:mm') : ""}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.admonition_notes}}</td>
                                    <td class="text-right">
                                        <button *ngIf="item.event_id != null" class="btn btn-info btn-sm"
                                            [routerLink]="['/events/detail', item.event_id]"><i
                                                class="fas fa-eye mr-1"></i> Visualizza</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingAdmonitions" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id" class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Assenze
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <table id="table_absences" datatable [dtOptions]="dtOptionsAbsences"
                            [dtTrigger]="dtTriggerAbsences" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Data evento</th>
                                    <th>Evento</th>
                                    <th>Stato</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of absences">
                                    <td>{{item.event_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.title}}</td>
                                    <td>
                                        <span *ngIf="item.perform != 1 && item.justified_absence == 0"
                                            class="badge badge-pill badge-danger text-uppercase">Assente non
                                            giustificato</span>

                                        <span *ngIf="item.perform != 1 && item.justified_absence == 1"
                                            class="badge badge-pill badge-success text-uppercase">Assente
                                            giustificato</span>

                                        <span *ngIf="item.perform_admonition == 1"
                                            class="badge badge-pill badge-warning text-uppercase">Ammonito</span>
                                    </td>
                                    <td class="text-right">
                                        <button *ngIf="item.perform !=1 && item.justified_absence == null"
                                            class="btn btn-success btn-sm" (click)="absenceJustify(item.event_id, 1)"><i
                                                class="fas fa-thumbs-up mr-1"></i>Giustifica</button>

                                        <button *ngIf="item.perform !=1 && item.justified_absence == null"
                                            class="btn btn-danger btn-sm" (click)="absenceJustify(item.event_id, 0)"><i
                                                class="fas fa-thumbs-down mr-1"></i>Non giustificare</button>

                                        <button
                                            *ngIf="item.perform !=1 && item.perform_admonition != 1 && item.justified_absence !=1"
                                            class="btn btn-warning btn-sm" [swal]="admonitionSwal"><i
                                                class="fas fa-exclamation mr-1"></i>Ammonisci</button>
                                        <swal #admonitionSwal title="Ammonisci"
                                            text="Con l'ammonizione andrai ad impostare anche l'assenza NON giustificata"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Ok, ammonisci!',  cancelButtonText: 'No' }"
                                            (confirm)="absenceAdmonition(item.event_id)"></swal>

                                        <button class="btn btn-info btn-sm"
                                            [routerLink]="['/events/detail', item.event_id]"><i
                                                class="fas fa-eye mr-1"></i> Visualizza</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingAbsences" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id && this.auth.currentUserValue.role_id == 0" class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Pagamenti
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <button class="btn btn-info mb-3" (click)="addPayment()"><i
                                class="fas fa-plus mr-1"></i>Aggiungi</button>

                        <table id="table_payments" datatable [dtOptions]="dtOptionsPayments"
                            [dtTrigger]="dtTriggerPayments" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th style="width: 15%">Data</th>
                                    <th style="width: 15%">Importo</th>
                                    <th style="width: 60%">Descrizione</th>
                                    <th style="width: 10%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of payments">
                                    <td>{{item.date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.amount / 100 | currency:'€':true:'1.2-2'}}</td>
                                    <td>{{item.description}}</td>
                                    <td class="text-right">
                                        <button class="btn btn-danger btn-sm" [swal]="deletePaymentSwal"><i
                                                class="fas fa-trash mr-2"></i>Elimina</button>
                                        <swal #deletePaymentSwal title="Elimina"
                                            text="Vuoi eliminare questo pagamento ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deletePayment(item.payment_id)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingPayments" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id && this.auth.currentUserValue.role_id == 0" class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Info Pagamenti
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <form [formGroup]="form_info_payment">

                            <div class="mb-3">
                                <label>Numero call for artist in cui si è stati selezionati</label>
                                <input formControlName="calls_count" type="number" class="form-control"
                                    placeholder="Inserisci numero"
                                    [ngClass]="{ 'is-invalid': submitted && f_info_payment.calls_count.errors }" />
                                <div *ngIf="f_info_payment.calls_count.errors?.required" class="invalid-feedback">Campo
                                    obbligatorio</div>
                            </div>

                            <div class="mb-3">
                                <label>Numero eventi</label>
                                <input formControlName="events_count" type="text" class="form-control"
                                    placeholder="Numero eventi" readonly="readonly" />
                            </div>

                            <div class="mb-3">
                                <label>Importo totale</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">€</span>
                                    </div>
                                    <input formControlName="total_amount" type='number' step='0.01' class="form-control"
                                        placeholder="Importo totale" readonly="readonly" />
                                </div>
                            </div>

                        </form>
                    </div>

                    <div class="card-footer text-center">
                        <button type="submit" class="btn btn-info" (click)="savePaymentDetail()">Salva</button>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id" class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Membri del gruppo
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <button class="btn btn-info mb-3" (click)="addGroupMember()"><i
                                class="fas fa-plus mr-1"></i>Aggiungi</button>

                        <table id="table_members" datatable [dtOptions]="dtOptionsMembers"
                            [dtTrigger]="dtTriggerMembers" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th style="width: 100px">Foto profilo</th>
                                    <th>Nome d'arte</th>
                                    <th>Referente</th>
                                    <th style="width: 15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of user?.members">
                                    <td class="align-middle">
                                        <img class="profile-user-img img-fluid img-circle elevation-1"
                                            style="width: 50px; height: 50px;"
                                            src="{{item?.profile_url ? item.profile_url:'assets/img/profile_placeholder.png'}}"
                                            alt="User profile picture">
                                    </td>
                                    <td class="align-middle">{{item.username}}</td>
                                    <td [ngSwitch]="item.is_referent">
                                        <span *ngSwitchCase="1"
                                            class="badge badge-pill badge-success text-uppercase">Referente</span>
                                    </td>
                                    <td class="align-middle text-right">
                                        <button class="btn btn-info btn-sm"
                                            [routerLink]="['/talents/detail', item.user_id]"><i
                                                class="fas fa-user mr-2"></i>Profilo</button>

                                        <button *ngIf="!item.is_referent" class="btn btn-danger btn-sm"
                                            [swal]="removeGroupMemberSwal"><i
                                                class="fas fa-trash mr-2"></i>Elimina</button>
                                        <swal #removeGroupMemberSwal title="Elimina"
                                            text="Vuoi eliminare questo membro dal gruppo ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="removeGroupMember(item.user_id)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingMembers" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Modal add/edit -->
<div class="modal fade" id="modal-default" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div>
                    <label>Assegna Città</label>
                    <div>
                        <ng-select [(ngModel)]="selectedDocumentCity" [items]="documentCities" bindLabel="city_name"
                            bindValue="city_id" [clearable]="false" placeholder="Scegli una città"
                            notFoundText="Nessuna città disponibile">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                <button type="submit" class="btn btn-info" data-dismiss="modal" [disabled]="!selectedDocumentCity"
                    (click)="openDocumentCity()">Aggiungi</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal payment -->
<div class="modal fade" id="modal-payment" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="form_payment">
                <div class="modal-body">

                    <div class="mb-3">
                        <label for="date">Data</label>
                        <input [owlDateTime]="dt_date" [owlDateTimeTrigger]="dt_date" type="text" class="form-control"
                            id="date" formControlName="date" readonly="readonly"
                            [ngClass]="{ 'is-invalid': submitted && f_payment.date.errors }">
                        <owl-date-time #dt_date></owl-date-time>
                        <div *ngIf="f_payment.date.errors?.required" class="invalid-feedback">Campo
                            obbligatorio</div>
                    </div>

                    <div class="mb-3">
                        <label>Importo</label>
                        <input formControlName="amount" type="text" class="form-control"
                            placeholder="Inserisci l'importo"
                            [ngClass]="{ 'is-invalid': submitted && f_payment.amount.errors }" />
                        <div *ngIf="f_payment.amount.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                    </div>

                    <div class="mb-3">
                        <label>Descrizione</label>
                        <input formControlName="description" type="text" class="form-control"
                            placeholder="Inserisci la descrizione"
                            [ngClass]="{ 'is-invalid': submitted && f_payment.description.errors }" />
                        <div *ngIf="f_payment.description.errors?.required" class="invalid-feedback">Campo obbligatorio
                        </div>
                    </div>

                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button type="submit" class="btn btn-info" data-dismiss="modal"
                        (click)="savePayment()">Aggiungi</button>
                </div>
            </form>
        </div>
    </div>
</div>