import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Place, PlaceDate, PlaceSlotTime } from 'src/app/models/place';
import { RestService } from 'src/app/utils/services/rest.service';
import { ModalPlaceSlotAddComponent } from '../modal-place-slot-add/modal-place-slot-add.component';
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeComponent,
  OwlDateTimeFormats
} from 'ng-pick-datetime';

@Component({
  selector: 'app-modal-place-dates',
  templateUrl: './modal-place-dates.component.html',
  styleUrls: ['./modal-place-dates.component.css']
})
export class ModalPlaceDatesComponent implements OnInit {

  @Input() placeID?: number;
  @Input() cityID?: number;
  @Input() date: PlaceDate;
  @Input() editMode: Boolean;
  @Input() removeMode: Boolean;

  places: Place[] = [];
  form: FormGroup;
  submitted = false;

  weekdaysData = [
    { id: 1, text: "Lunedi" }, { id: 2, text: "Martedi" },
    { id: 3, text: "Mercoledi" }, { id: 4, text: "Giovedi" },
    { id: 5, text: "Venerdi" }, { id: 6, text: "Sabato" },
    { id: 7, text: "Domenica" }
  ]

  calendarStartView = "month"; // multi-years

  constructor(public activeModal: NgbActiveModal, private rest: RestService, private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      date_type: new FormControl(0),
      start_end_date: new FormControl(
        {
          disabled: this.date,
          value: this.date ? [moment(this.date.date, 'YYYY-MM-DD'), moment(this.date.date, 'YYYY-MM-DD')] : null
          //value: this.date ? [new Date(moment(this.date.date, 'YYYY-MM-DD').unix() * 1000), new Date(moment(this.date.date, 'YYYY-MM-DD').unix() * 1000)] : null
        },
        Validators.required),
      weekdays: new FormArray([], minSelectedCheckboxes(1)),
      places: new FormControl(null),
    });

    this.addCheckboxes();

    if (this.cityID != null) {
      this.placesList(this.cityID);
    }
  }

  get f() {
    return this.form.controls;
  }

  get weekdaysFormArray() {
    return this.form.controls.weekdays as FormArray;
  }

  private addCheckboxes() {
    this.weekdaysData.forEach(() => this.weekdaysFormArray.push(new FormControl(false)));
  }

  /**
 * Lista dei palchi
 */
  placesList(city_id) {
    this.rest.placesCitiesList(city_id).subscribe(res => {
      this.places = res.data.places;
    });
  }

  /**
   * Cambio della tipologia data
   */

  dateTypeChange() {
    this.form.controls.start_end_date.setValue(null);
    this.calendarStartView = (this.f.date_type.value == 0) ? "month" : "multi-years";
  }

  /**
   * Anno selezionato
   */

  chosenYearHandler(normalizedYear: Moment, datepicker: OwlDateTimeComponent<Moment>) {
    if (this.f.date_type.value == 0) { return; } // avoid if not single year mode

    let startOfYear = moment(normalizedYear).startOf('year');
    let endOfYear = moment(normalizedYear).endOf('year');
    let startDate = new Date(startOfYear.unix() * 1000);
    let endDate = new Date(endOfYear.unix() * 1000);
    this.form.controls.start_end_date.setValue([startDate, endDate]);
    datepicker.close();
  }

  /**
   * Date picker chiuso
   */

  afterPickerClosed() {
    if (this.f.date_type.value == 0) { return; } // avoid if not single year mode

    let values = this.form.controls.start_end_date.value;
    if (values == null || values.length < 1) { return; }

    let startOfYear = moment(this.form.controls.start_end_date.value[0]).startOf('year');
    let endOfYear = moment(this.form.controls.start_end_date.value[0]).endOf('year');
    let startDate = new Date(startOfYear.unix() * 1000);
    let endDate = new Date(endOfYear.unix() * 1000);
    this.form.controls.start_end_date.setValue([startDate, endDate]);
  }


  getSlot(item) {
    return moment(item, "HH:mm:ss").format("HH:mm");
  }

  showSlotModal() {
    const modalRef = this.modalService.open(ModalPlaceSlotAddComponent);
    modalRef.componentInstance.alreadyHasWholeDay = this.date?.slots.some(i => i.whole_day == 1);
    modalRef.result.then((result: PlaceSlotTime) => {
      this.updateSlot(result.start_time, result.end_time, result.whole_day);
    }, (reason) => { });
  }

  updateSlot(start_time, end_time, whole_day) {
    var slot = new PlaceSlotTime();
    slot.start_time = start_time;
    slot.end_time = end_time;
    slot.whole_day = whole_day;

    if (!this.date) {
      this.date = new PlaceDate();
    }

    if (this.editMode) {
      // aggiungere subito lo slot        
      this.rest.placeSlotAdd(this.placeID, this.date.date, slot.start_time, slot.end_time, slot.whole_day).subscribe(res => {
        if (res && res.data && res.data.slot) this.date.slots.push(res.data.slot);
        else this.toastr.error('Si è verificato un errore, riprova!');
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      // aggiornare lista
      this.date.slots.push(slot);
    }
  }

  /**
   * Eliminazione di uno slot
   */
  deleteSlot(item: PlaceSlotTime) {
    if (this.editMode) {
      this.rest.placeSlotDelete(item.slot_id).subscribe(res => {
        this.date.slots = this.date.slots.filter(i => i.slot_id != item.slot_id);

        // ultmo slot quindi chiudo popup
        if (this.date.slots.length == 0) {
          this.activeModal.close(100);
        }
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.date.slots.indexOf(item) >= 0 ? this.date.slots.splice(this.date.slots.indexOf(item), 1) : null;
      if (this.date.slots.length == 0) {
        this.date = null;
      }
    }
  }

  save() {
    this.submitted = true;

    if (this.cityID != null) {
      this.f.places.setValidators([Validators.required]);
      this.f.places.updateValueAndValidity();
    }

    if (this.form.invalid) {
      return;
    }

    if (!this.date || !this.date.slots || this.date.slots.length == 0) {
      this.toastr.error('Inserisci almeno uno slot!');
      return;
    }

    var startDate = moment(this.f.start_end_date.value[0]).format("YYYY-MM-DD");
    var endDate = moment(this.f.start_end_date.value[1]).format("YYYY-MM-DD");
    var times = this.date.slots;

    var selectedWeekdaydIds = this.form.value.weekdays
      .map((checked, i) => checked ? this.weekdaysData[i].id : null)
      .filter(v => v !== null);

    if (this.removeMode) {
      this.rest.placeDatesSlotsDelete(this.placeID, startDate, endDate, times, selectedWeekdaydIds).subscribe(res => {
        this.toastr.success('Date aggiunte con successo!');
        this.activeModal.close();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      let places = (this.placeID != null) ? [this.placeID] : this.f.places.value;

      this.rest.placeDateAdd(places, startDate, endDate, times, selectedWeekdaydIds).subscribe(res => {
        this.toastr.success('Date aggiunte con successo!');
        this.activeModal.close();
      }, error => {
        if (error.code == 50) {
          this.toastr.error('Hai selezionato date già utilizzate, modifica e riprova!');
        } else {
          this.toastr.error('Si è verificato un errore, riprova!');
        }
      });
    }

  }

  dismiss() {
    this.activeModal.dismiss();
  }

  /**
   * Seleziona tutti i weekdays
   */

  selectAllWeekdays() {
    for (var i = 0; i < this.weekdaysFormArray.length; i++) {
      this.weekdaysFormArray.controls[i].setValue(true);
    }
  }

}

function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
      .map(control => control.value)
      // total up the number of checked checkboxes
      .reduce((prev, next) => next ? prev + next : prev, 0);

    // if the total is not greater than the minimum, return the error message
    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}