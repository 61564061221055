import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { City } from 'src/app/models/city';
import { RestService } from 'src/app/utils/services/rest.service';
declare var $: any;

@Component({
  selector: 'app-modal-cities',
  templateUrl: './modal-cities.component.html',
  styleUrls: ['./modal-cities.component.css']
})
export class ModalCitiesComponent implements OnInit {

  @Input() cities: City[];

  form: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private rest: RestService
  ) { }

  ngOnInit(): void {

    this.form = new FormGroup({
      cities: new FormControl(this.cities),
    });

    this.rest.citiesList().subscribe(res => {
      this.cities = res.data.cities;
    }, error => {
    });
  }

  get f() {
    return this.form.controls;
  }

  public onSelectAll() {
    const selected = this.cities.map(item => item.city_id);
    this.f.cities.patchValue(selected);
  }

  public onClearAll() {
    this.f.cities.patchValue([]);
  }

  apply() {
    this.activeModal.close(this.f.cities.value)
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
