import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { RestService } from 'src/app/utils/services/rest.service';
import { User } from 'src/app/models/user';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.css']
})
export class RankingComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = true;

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject();

  users: User[] = [];
  isUpdate: boolean;

  constructor(private rest: RestService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      ordering: false,
      paging: true,
      searching: true,
      responsive: true,
      // order: [5, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      dom: "<'mb-3 text-center'B><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          extend: 'print',
          text: 'Stampa',
          title: 'Officine Buone » Classifica'
        },
        {
          extend: 'pdfHtml5',
          text: 'Esporta PDF',
          filename: 'Officine Buone - Classifica',
          title: 'Officine Buone » Classifica'
        },
        {
          extend: 'excelHtml5',
          text: 'Esporta Excel',
          filename: 'Officine Buone - Classifica',
          title: 'Officine Buone » Classifica'
        }
      ]
    };

    this.rankingsList();
  }

  /**
  * Lista classifica
  */
  rankingsList() {
    this.isLoading = true;

    this.rest.rankingsList().subscribe(res => {
      this.isLoading = false;
      this.users = res.data.users;

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }

    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
