import { Component } from '@angular/core';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'officinebuone';

  constructor(modalConfig: NgbModalConfig) { 
    modalConfig.keyboard = false;
    modalConfig.centered = true;
    modalConfig.backdrop = 'static';
  }

}


