<form [formGroup]="form">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-12 text-center">
                <div class="text-center mb-3">
                    <img class="image" src="{{f.image_url.value ? f.image_url.value :'/assets/img/no-image.png'}}" alt="Cover">
                    <div>
                        <div *ngIf="fileCover"><small>Dimensione: {{fileCover?.size / 1024 / 1024 | number : '1.2-2'}} MB</small></div>
                        <small>L'immagine non deve superare 1MB</small><br>
                    </div>
                </div>

                <div>
                    <input #img_cover hidden="true" type="file" onclick="this.value=null" (change)="handleFileInputCover($event.target.files)" accept=".jpg, .png" />
                    <button type="button" class="btn btn-info" (click)="img_cover.click()">Scegli immagine</button>
                </div>
            </div>

            <div class="form-group col-12">
                <label>Nome</label>
                <input formControlName="name" type="text" class="form-control" placeholder="Inserici un nome" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                <div *ngIf="f.name.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
            </div>

            <div class="form-group col-12">
                <label>Associa i totem</label>
                <div class="select2-info">
                    <select id="places" formControlName="places" class="form-control" data-dropdown-css-class="select2-info" data-placeholder="Scegli uno o più ...." multiple style="width: 100%;">
                    </select>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
        <button type="submit" class="btn btn-info" [disabled]="loading" (click)="save()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Salva</button>
    </div>
</form>