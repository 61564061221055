import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RestService } from 'src/app/utils/services/rest.service';
import { User } from 'src/app/models/user';
declare var $: any;
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { City } from 'src/app/models/city';
import { Musical_Genre } from 'src/app/models/musical_genre';
import { AppConstants } from 'src/app/utils/constants';
import { log } from 'console';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css'],
})
export class UserDetailComponent implements OnInit {
  id: number;

  form: FormGroup;
  form_access: FormGroup;

  submitted = false;
  submitted_access = false;

  loading = false;
  loading_access = false;

  user: User;
  cities: City[] = [];
  musical_genres: Musical_Genre[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rest: RestService,
    private toastr: ToastrService,
    private appConstants: AppConstants,
    public auth: AuthenticationService
  ) {
    this.id = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {

    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      surname: new FormControl(null, Validators.required),
      email: new FormControl({ value: null, disabled: true }, Validators.required),
      phone: new FormControl(null, Validators.required),
      username: new FormControl(null, Validators.required),
      enabled: new FormControl(true),
      city_id: new FormControl(null, Validators.required),
      score: new FormControl(null, Validators.required),
      preferences: new FormControl(null),
      musical_genres: new FormControl(null)
    });

    this.form_access = new FormGroup({
      email: new FormControl(null, Validators.required),
    });

    $('#preferences').select2({
      allowClear: true,
    });

    $('#musical_genres').select2({
      allowClear: true,
    });

    this.professionsList()
    this.citiesList();
    this.musicalGenresList()
    this.userDetail();
  }

  get f() {
    return this.form.controls;
  }

  get fa() {
    return this.form_access.controls;
  }

  // Professions 

  professionsList() {
    this.rest.professionsList(0, null).subscribe(res => {
      for (let profession of res.data.professions) {
        var data = { id: profession.profession_id, text: profession.name };
        var newOption = new Option(data.text, data.id.toString(), false, false);
        $('#preferences').append(newOption).trigger('change');
      }
      if (this.user) {
        $('#preferences').val(this.user.preferences).trigger("change");
      }
    });
  }

  /**
  * Lista delle città
  */
  citiesList() {
    this.rest.citiesList().subscribe(res => {
      this.cities = res.data.cities;
    });
  }

  /**
  * Lista dei generi musicali
  */
  musicalGenresList() {
    this.rest.musicalGenresList().subscribe(res => {
      this.musical_genres = res.data.musical_genres;

      for (let genre of this.musical_genres) {
        var data = { id: genre.genre_id, text: genre.name };
        var newOption = new Option(data.text, data.id.toString(), false, false);
        $('#musical_genres').append(newOption).trigger('change');
      }

      if (this.user) {
        $('#musical_genres').val(this.user.musical_genres).trigger("change");
      }
    });
  }

  userDetail() {
    this.rest.userDetail(this.id).subscribe(res => {
      this.user = res.data.user;

      this.form.setValue({
        name: this.user.name,
        surname: this.user.surname,
        email: this.user.email,
        phone: this.user.phone,
        username: this.user.username,
        enabled: this.user.enabled == 1,
        score: this.user.score,
        city_id: this.user.preference_city_id,
        preferences: this.user.preferences,
        musical_genres: this.user.musical_genres
      })

      this.form_access.setValue({
        email: this.user.email,
      });

      $('#preferences').val(this.user.preferences).trigger("change");

      $('#musical_genres').val(this.user.musical_genres).trigger("change");

    });
  }

  saveAccessData() {
    this.submitted_access = true;

    if (this.form_access.invalid) {
      return;
    }

    var bodyData = {
      user_id: this.user.user_id,
      email: this.fa.email.value
    };

    this.loading_access = true;

    this.rest.userEmailEdit(bodyData).subscribe(res => {
      this.loading_access = false;
      this.toastr.success('Email aggiornata con successo!');
    }, error => {
      this.loading_access = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });

  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    var preferencesSelected = $("#preferences").select2('data');
    var preferencesIds = [];
    for (let pref of preferencesSelected) {
      preferencesIds.push(pref.id);
    }

    var musicalGenresSelected = $("#musical_genres").select2('data');
    var musicalGenresIds = [];
    for (let genre of musicalGenresSelected ?? []) {
      musicalGenresIds.push(genre.id);
    }

    var bodyData = {
      user_id: this.user.user_id,
      type: this.user.type,
      name: this.f.name.value,
      surname: this.f.surname.value,
      phone: this.f.phone.value,
      username: this.f.username.value,
      preference_city_id: this.f.city_id.value,
      score: this.f.score.value,
      enabled: this.f.enabled.value ? 1 : 0,
      preferences: preferencesIds.toString(),
      musical_genres: musicalGenresIds.toString(),
      stars: 0
    };

    this.loading = true;

    this.rest.userEdit(bodyData).subscribe(res => {
      this.loading = false;
      this.toastr.success('Profilo aggiornato con successo!');
    }, error => {
      this.loading = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  // Delete
  deleteUser() {
    this.loading = true;

    this.rest.userDelete(this.id).subscribe(res => {
      this.loading = false;
      this.toastr.success('Utente eliminato con successo!');
      this.router.navigate(['/users']);
    }, error => {
      this.loading = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }
}
