export class City {
    city_id: number;
    city_name: string;
    country_id?: number;
    country_name?: string;
    image_url?: string;
    email_logo_url?: string;
    email_logo_hex_color?: string;
    app_logo_url?: string;
    geolocation_required: number;
    enabled: number;
    emission_url?: string;

    talent_month_events_limit: number;
    talent_slots_visibility_type: number;
    talent_slots_visibility_value: number;
    profession_month_events_limit: number;
    profession_slots_visibility_type: number;
    profession_slots_visibility_value: number;

    public static getIds(cities: City[]) {
        var ids = [];
        for (let city of cities) {
            ids.push(city.city_id);
        }
        return ids;
    }
}