import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // if (err.status === 401 && !('error' in err) && !err.url.includes("auth/signin")) {
            //     // auto logout if 401 response returned from api
            //     this.authenticationService.logout();
            //     location.reload(true);
            // }

            // const error = err.error.message || err.statusText;
            // return throwError(error);

            // verifica codice errore e se non c'è un code custom...
            // voul dire che non sei autorizzato
            if (err.status === 401 && !err.error.code) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            }

            const code = err.error.code || err.status || err.statusText;
            const message = err.error.message || err.message;

            const response = {};
            response['code'] = code;
            response['message'] = message;

            return throwError(response);
        }))
    }
}