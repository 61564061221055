import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SigninResponse } from 'src/app/models/response/signin-response';
import { Admin } from 'src/app/models/admin';
import { Base } from 'src/app/models/base';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Admin>;
  public currentUser: Observable<Admin>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<Admin>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Admin {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<SigninResponse>(`${environment.APIweb}/auth/signin`, { email, password })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        if (response && response.data && response.data.admin && response.data.auth.token) {
          localStorage.setItem('currentUser', JSON.stringify(response.data.admin));
          localStorage.setItem('token', response.data.auth.token);
          this.currentUserSubject.next(response.data.admin);
        }

        return response;
      }));
  }

  verifyEmail(token: string) {
    return this.http.post<Base>(`${environment.APIapp}/auth/email/verify`, { token });
  }

  changePassword(password: string, token: string) {
    return this.http.post<Base>(`${environment.APIapp}/auth/password/change`, { password, token });
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    this.currentUserSubject.next(null);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  isLogged() {
    return this.getToken() != null;
  }

  isAdmin(): boolean {
    return this.currentUserValue.role_id == 0;
  }
  isManager(): boolean {
    return this.currentUserValue.role_id == 1;
  }
  isMunicipality(): boolean {
    return this.currentUserValue.role_id == 2;
  }
  isManagerPlace(): boolean {
    return this.currentUserValue.role_id == 3;
  }
}
