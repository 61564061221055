import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PlaceSlotTime } from 'src/app/models/place';

@Component({
  selector: 'app-modal-place-slot-add',
  templateUrl: './modal-place-slot-add.component.html',
  styleUrls: ['./modal-place-slot-add.component.css']
})
export class ModalPlaceSlotAddComponent implements OnInit {
  @Input() alreadyHasWholeDay: boolean;

  form: FormGroup;
  submitted = false;

  start_time = new Date();
  end_time = new Date();

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      start_time: new FormControl(null, Validators.required),
      end_time: new FormControl(null, Validators.required),
      whole_day: new FormControl(false, Validators.required)
    });

    this.start_time.setMinutes(0);
    this.f.start_time.setValue(this.start_time);

    this.end_time.setMinutes(0);
    this.end_time.setHours(this.end_time.getHours() + 1);
    this.f.end_time.setValue(this.end_time);
  }

  get f() {
    return this.form.controls;
  }

  wholeDayChange(event) {
    this.start_time = new Date();
    this.end_time = new Date();

    this.start_time.setMinutes(0);
    this.end_time.setMinutes(0);

    if (this.f.whole_day.value) {
      this.start_time.setHours(9);
      this.end_time.setHours(23);
    } else {
      this.end_time.setHours(this.end_time.getHours() + 1);
    }

    this.f.start_time.setValue(this.start_time);
    this.f.end_time.setValue(this.end_time);
  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    var slot = new PlaceSlotTime();
    slot.start_time = moment(this.f.start_time.value).format("HH:mm:00");
    slot.end_time = moment(this.f.end_time.value).format("HH:mm:00");
    slot.whole_day = this.f.whole_day.value ? 1 : 0;

    this.activeModal.close(slot)
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
