import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { RestService } from 'src/app/utils/services/rest.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { City } from 'src/app/models/city';
import { Manager } from 'src/app/models/manager';
import { Place } from 'src/app/models/place';
declare var $: any;

@Component({
  selector: 'app-managers',
  templateUrl: './managers.component.html',
  styleUrls: ['./managers.component.css']
})
export class ManagersComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild('password') password: ElementRef;

  constructor(private rest: RestService, private toastr: ToastrService) { }

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isLoading: boolean = true;

  form: FormGroup;
  submitted = false;

  managers: Manager[] = [];
  cities: City[];
  places: Place[];

  isUpdate: boolean;

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [5] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = new FormGroup({
      manager_id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      surname: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null),
      role_id: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      places: new FormControl(null),
      notify_email_enabled: new FormControl(0, Validators.required),
    });

    this.citiesList();
    this.managersList();
  }

  get f() {
    return this.form.controls;
  }

  /**
 * Lista delle città
 */
  citiesList() {
    this.rest.citiesList().subscribe(res => {
      this.cities = res.data.cities;
    });
  }

  cityChange() {
    this.f.places.setValue(null);
    if (this.f.city.value != null) {
      this.placesList(this.f.city.value);
    }
  }

  /**
 * Lista dei palchi
 */
  placesList(city_id) {
    this.rest.placesCitiesList(city_id).subscribe(res => {
      this.places = res.data.places;
    });
  }

  /**
   * Lista dei managers
   */
  managersList() {
    this.isLoading = true;

    this.rest.managersList().subscribe(res => {
      this.isLoading = false;

      this.managers = res.data.managers;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
 * Popup aggiunta/modifica città
 */
  edit(item: Manager) {
    this.submitted = false;

    if (item) {
      this.f.manager_id.setValue(item.manager_id);
      this.f.name.setValue(item.name);
      this.f.surname.setValue(item.surname);
      this.f.email.setValue(item.email);
      this.f.role_id.setValue(item.role_id);
      this.f.notify_email_enabled.setValue(item.notify_email_enabled);

      this.password.nativeElement.placeholder = "Modifica password (opzionale)";
      this.f.password.setValue('');

      if (item.role_id == 1) {
        this.f.city.setValue(City.getIds(item.cities));
      } else {
        this.f.city.setValue(City.getIds(item.cities)[0]);
      }

      this.f.places.setValue(Place.getIds(item.places));

      if (item.role_id == 3) {
        this.placesList(City.getIds(item.cities)[0]);
      }

    } else {
      this.password.nativeElement.placeholder = "Inserici una password";

      this.form.reset();
    }
    $("#modal-default").modal('show');
  }

  /**
   * Salvataggio
   */
  save() {
    this.submitted = true;

    var managerID = this.f.manager_id.value;

    if (managerID && this.f.password) {
      this.f.password.setValidators([Validators.minLength(8), Validators.maxLength(45)]);
      this.f.password.updateValueAndValidity();
    } else {
      this.f.password.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(45)]);
      this.f.password.updateValueAndValidity();
    }

    if (this.f.role_id.value && this.f.role_id.value == 3) {
      this.f.places.setValidators([Validators.required]);
      this.f.places.updateValueAndValidity();
    } else {
      this.f.places.setValidators([]);
      this.f.places.updateValueAndValidity();
    }

    if (this.form.invalid) {
      return;
    }

    var name = this.f.name.value;
    var surname = this.f.surname.value;
    var email = this.f.email.value;
    var password = this.f.password.value;
    var role_id = this.f.role_id.value;
    var cityIds = this.f.city.value.toString();
    var placesIds = (role_id == 3 && this.f.places.value) ? this.f.places.value.toString() : null;
    var notify_email_enabled = this.f.notify_email_enabled.value;

    if (managerID) {
      this.rest.managerEdit(managerID, name, surname, email, password ? password : null, cityIds, placesIds, role_id, notify_email_enabled).subscribe(res => {
        $("#modal-default").modal('hide');
        this.toastr.success('Organizzatore modificato con successo!');
        this.managersList();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.managerAdd(name, surname, email, password, cityIds, placesIds, role_id, notify_email_enabled).subscribe(res => {
        $("#modal-default").modal('hide');
        this.toastr.success('Organizzatore aggiunto con successo!');
        this.managersList();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }

  }

  /**
 * Elimina
 */
  delete(item: Manager) {
    this.rest.managerDelete(item.manager_id).subscribe(res => {
      this.toastr.success('Orgnizzatore eliminato con successo!');
      this.managersList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
