<section class="content-header">
    <div class="container-fluid">
        <h1>Scheda Utente</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-3">
                <div class="card card-widget widget-user">
                    <div class="widget-user-header bg-warning">
                        <h3 class="widget-user-username">{{user?.name}} {{user?.surname}}</h3>
                        <h5 class="widget-user-desc">{{user?.username}}</h5>
                    </div>
                    <div class="widget-user-image">
                        <img class="img-circle elevation-2"
                            src="{{user?.profile_url ? user.profile_url:'assets/img/profile_placeholder.png'}}"
                            alt="User profile picture">
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-8 border-right">
                                <div class="description-block">
                                    <h5 class="description-header">{{user?.creation_date | date :'dd/MM/yyyy HH:mm'}}
                                    </h5>
                                    <span class="description-text">Data registrazione</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="description-block">
                                    <h5 class="description-header">{{user?.score}}</h5>
                                    <span class="description-text">Punti</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <form *ngIf="id && user?.access_type==0" [formGroup]="form_access">
                    <div class="card card-secondary">
                        <div class="card-header text-uppercase">
                            Dati di accesso
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label for="email">Email</label>
                                        <input type="text" class="form-control" id="email" formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted_access && fa.email.errors }">
                                        <div *ngIf="fa.email.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="saveAccessData()"> <span
                                    *ngIf="loading_access" class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-9">
                <div class="col-12">
                    <form [formGroup]="form">
                        <div class="card card-info">
                            <div class="card-header text-uppercase">
                                Informazioni
                            </div>

                            <div class="card-body">
                                <div class="post">

                                    <div class="row">
                                        <div class="form-group col-12 col-lg-6">
                                            <label for="name">Nome</label>
                                            <input type="text" class="form-control" id="name" formControlName="name"
                                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                            <div *ngIf="f.name.errors?.required" class="invalid-feedback">Campo
                                                obbligatorio
                                            </div>
                                        </div>

                                        <div class="form-group col-12 col-lg-6">
                                            <label for="surname">Cognome</label>
                                            <input type="text" class="form-control" id="surname"
                                                formControlName="surname"
                                                [ngClass]="{ 'is-invalid': submitted && f.surname.errors }">
                                            <div *ngIf="f.surname.errors?.required" class="invalid-feedback">Campo
                                                obbligatorio</div>
                                        </div>

                                        <div [hidden]="id" class="form-group col-12 col-lg-6">
                                            <label for="email">Email</label>
                                            <input type="text" class="form-control" id="email" formControlName="email"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="f.email.errors?.required" class="invalid-feedback">Campo
                                                obbligatorio</div>
                                        </div>

                                        <div class="form-group col-12 col-lg-6">
                                            <label for="phone">Telefono</label>
                                            <input type="text" class="form-control" id="phone" formControlName="phone"
                                                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                            <div *ngIf="f.phone.errors?.required" class="invalid-feedback">Campo
                                                obbligatorio</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="post">
                                    <div class="row">
                                        <div class="form-group col-12 col-lg-6">
                                            <label for="username">Username</label>
                                            <input type="text" class="form-control" id="username"
                                                formControlName="username"
                                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                                            <div *ngIf="f.username.errors?.required" class="invalid-feedback">Campo
                                                obbligatorio</div>
                                        </div>

                                        <div class="form-group col-12 col-lg-6">
                                            <label>Città di preferenza</label>
                                            <select formControlName="city_id" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }">
                                                <option [ngValue]="null" disabled>Scegli una Città</option>
                                                <option *ngFor="let d of cities; let i = index;" [ngValue]="d.city_id">
                                                    {{d.city_name}}
                                                </option>
                                            </select>
                                            <div *ngIf="f.city_id.errors?.required" class="invalid-feedback">Campo
                                                obbligatorio
                                            </div>
                                        </div>

                                        <div class="form-group col-12 col-lg-6">
                                            <label>Cosa ti piace</label>
                                            <div class="select2-info">
                                                <select id="preferences" formControlName="preferences"
                                                    class="form-control" data-dropdown-css-class="select2-info"
                                                    data-placeholder="Scegli una o più ...." multiple
                                                    style="width: 100%;">
                                                </select>
                                            </div>
                                        </div>

                                        <div *ngIf="0" class="form-group col-12 col-lg-6">
                                            <label>Generi musicali preferiti</label>
                                            <div class="select2-info">
                                                <select id="musical_genres" formControlName="musical_genres"
                                                    class="form-control" data-dropdown-css-class="select2-info"
                                                    data-placeholder="Scegli una o più ...." multiple
                                                    style="width: 100%;">
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group col-12 col-lg-6">
                                            <label for="score">Punti</label>
                                            <input type="number" class="form-control" id="score" formControlName="score"
                                                [ngClass]="{ 'is-invalid': submitted && f.score.errors }">
                                            <div *ngIf="f.score.errors?.required" class="invalid-feedback">Campo
                                                obbligatorio</div>
                                        </div>

                                        <div class="form-group col-12 text-center">
                                            <ui-switch formControlName="enabled"></ui-switch>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div *ngIf="this.auth.isAdmin() || this.auth.isMunicipality()"
                                class="card-footer text-center">
                                <button type="submit" class="btn btn-info" (click)="save()"> <span *ngIf="loading"
                                        class="spinner-border spinner-border-sm mr-1"></span>
                                    Salva</button>

                                <button *ngIf="id"
                                    [disabled]="!this.auth.isAdmin()"
                                    class="btn btn-danger btn-info" [swal]="deleteSwal"><i
                                        class="fas fa-trash mr-2"></i>Elimina</button>
                                <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo utente ?" icon="warning"
                                    [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                    (confirm)="deleteUser()"></swal>
                            </div>
                        </div>

                    </form>
                </div>



            </div>
        </div>
    </div>
</section>


<!-- <section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <div class="text-center mb-3">
                    <img class="profile-user-img img-fluid img-circle elevation-1" style="width: 150px; height: 150px;"
                        src="{{user?.profile_url ? user.profile_url:'assets/img/profile_placeholder.png'}}"
                        alt="User profile picture">
                </div>

                <form *ngIf="id && user?.access_type==0" [formGroup]="form_access">
                    <div class="card card-info">
                        <div class="card-header text-uppercase">
                            Dati di accesso
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label for="email">Email</label>
                                        <input type="text" class="form-control" id="email" formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted_access && fa.email.errors }">
                                        <div *ngIf="fa.email.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="saveAccessData()"> <span
                                    *ngIf="loading_access" class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>
                        </div>
                    </div>
                </form>

                <form [formGroup]="form">
                    <div class="card card-info">
                        <div class="card-header text-uppercase">
                            Informazioni
                        </div>

                        <div class="card-body">
                            <div class="post">

                                <div class="row">
                                    <div class="form-group col-12 col-lg-6">
                                        <label for="name">Nome</label>
                                        <input type="text" class="form-control" id="name" formControlName="name"
                                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                        <div *ngIf="f.name.errors?.required" class="invalid-feedback">Campo obbligatorio
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="surname">Cognome</label>
                                        <input type="text" class="form-control" id="surname" formControlName="surname"
                                            [ngClass]="{ 'is-invalid': submitted && f.surname.errors }">
                                        <div *ngIf="f.surname.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div [hidden]="id" class="form-group col-12 col-lg-6">
                                        <label for="email">Email</label>
                                        <input type="text" class="form-control" id="email" formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <div *ngIf="f.email.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="phone">Telefono</label>
                                        <input type="text" class="form-control" id="phone" formControlName="phone"
                                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                        <div *ngIf="f.phone.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>
                                </div>
                            </div>

                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12 col-lg-6">
                                        <label for="username">Username</label>
                                        <input type="text" class="form-control" id="username" formControlName="username"
                                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                                        <div *ngIf="f.username.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label>Città di preferenza</label>
                                        <select formControlName="city_id" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }">
                                            <option [ngValue]="null" disabled>Scegli una Città</option>
                                            <option *ngFor="let d of cities; let i = index;" [ngValue]="d.city_id">
                                                {{d.city_name}}
                                            </option>
                                        </select>
                                        <div *ngIf="f.city_id.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label>Cosa ti piace</label>
                                        <div class="select2-info">
                                            <select id="preferences" formControlName="preferences" class="form-control"
                                                data-dropdown-css-class="select2-info"
                                                data-placeholder="Scegli una o più ...." multiple style="width: 100%;">
                                            </select>
                                        </div>
                                    </div>

                                    <div *ngIf="0" class="form-group col-12 col-lg-6">
                                        <label>Generi musicali preferiti</label>
                                        <div class="select2-info">
                                            <select id="musical_genres" formControlName="musical_genres"
                                                class="form-control" data-dropdown-css-class="select2-info"
                                                data-placeholder="Scegli una o più ...." multiple style="width: 100%;">
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="score">Punti</label>
                                        <input type="number" class="form-control" id="score" formControlName="score"
                                            [ngClass]="{ 'is-invalid': submitted && f.score.errors }">
                                        <div *ngIf="f.score.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 text-center">
                                        <ui-switch formControlName="enabled"></ui-switch>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()"> <span *ngIf="loading"
                                    class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</section> -->