import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { City } from 'src/app/models/city';
import { Document } from 'src/app/models/document';
import { DocumentCity } from 'src/app/models/document_city';
import { GroupMember } from 'src/app/models/group-member';
import { Profession } from 'src/app/models/profession';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-group-document',
  templateUrl: './group-document.component.html',
  styleUrls: ['./group-document.component.css']
})
export class GroupDocumentComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  saving = false;
  isLoading = false;
  user_id: number;
  city_id: number;

  user: User;
  city: DocumentCity;
  document: Document;
  professions: Profession[];
  members: GroupMember[];

  dtOptionsMembers: DataTables.Settings = {};
  dtTriggerMembers: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rest: RestService,
    private toastr: ToastrService,
    public auth: AuthenticationService,
  ) {
    this.user_id = this.route.snapshot.params['id'];
    this.city_id = this.route.snapshot.params['city_id'];
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      document_video_url: new FormControl(),
      document_emission: new FormControl(),
      document_emission_2: new FormControl(),
      document_emission_2_description: new FormControl(),
      document_status: new FormControl(0),
      document_status_reason: new FormControl(),
      professions: new FormControl()
    });

    this.dtOptionsMembers = {
      columnDefs: [
        { orderable: false, targets: [0, 4] }
      ],
      order: [1, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      destroy: true,
      paging: false,
      searching: false
    };

    this.getDocumentDetail();
    this.professionsList();
  }

  get f() {
    return this.form.controls;
  }

  getDocumentDetail() {
    this.isLoading = true;

    this.rest.documentsDetail(this.user_id, this.city_id).subscribe(res => {
      this.document = res.data.document;
      this.city = res.data.city;
      this.user = res.data.user;
      this.members = res.data.members;
      
      if (this.document) {
        this.form.setValue({
          document_video_url: this.document.document_video_url,
          document_status: this.document.document_status,
          document_status_reason: (this.document.document_status_reason != null) ? this.document.document_status_reason : "",
          document_emission: (this.document.document_emission != null) ? this.document.document_emission : 1,
          document_emission_2: this.document.document_emission_2,
          document_emission_2_description: this.document.document_emission_2_description,    
          professions: Document.getProfessionIds(this.document.professions)
        })
      }

      this.dtTriggerMembers.next();

      this.isLoading = false;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  // Professions 
  professionsList() {
    this.rest.professionsList(1, 1).subscribe(res => {
      this.professions = res.data.professions;
    });
  }

  // Update document
  save() {
    if (this.members != null) {
      var allApproved = this.members.filter(u => u.document_status == null || u.document_status != 1);
      if (allApproved.length > 0) {
        this.toastr.error("Tutti i membri del gruppo devono avere il documento approvato per questa città");
        return;
      }
    }

    this.f.document_video_url.setValidators(this.city.document == 1 ? [Validators.required] : null);
    this.f.document_video_url.updateValueAndValidity();

    this.f.document_emission.setValidators(this.city.document == 1 ? [Validators.required] : null);
    this.f.document_emission.updateValueAndValidity();

    this.f.document_emission_2_description.setValidators(this.f.document_emission_2.value == 5 ? [Validators.required] : null);
    this.f.document_emission_2_description.updateValueAndValidity();

    this.f.professions.setValidators(this.city.document == 1 ? [Validators.required] : null);
    this.f.professions.updateValueAndValidity();

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('user_id', this.user_id.toString());
    formData.append('city_id', this.city_id.toString());

    if (this.city.document == 1) {
      formData.append("document_status", this.f.document_status.value.toString());

      if (this.f.document_status.value == 2 && this.f.document_status_reason.value) {
        formData.append("document_status_reason", this.f.document_status_reason.value.toString());
      } else {
        formData.append("document_status_reason", "");
      }

      formData.append("document_video_url", this.f.document_video_url.value);
      formData.append("document_emission", this.f.document_emission.value);
      if (this.f.document_emission_2.value != null) formData.append("document_emission_2", this.f.document_emission_2.value);
      if (this.f.document_emission_2.value != null) formData.append("document_emission_2_description", this.f.document_emission_2_description.value);
  
      formData.append("professions", this.f.professions.value.toString());
    } else {
      formData.append("document_status", "1");
    }

    this.saving = true;

    this.rest.documentEdit(formData).subscribe(res => {
      this.saving = false;
      this.toastr.success('Concessione aggiornata con successo!');
      this.getDocumentDetail();
    }, error => {
      this.saving = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  ngOnDestroy(): void {
    this.dtTriggerMembers.unsubscribe();
  }
}
