import { Injectable } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class Utils {
  static updateDataTable(dtUpdate: boolean, dtElement: DataTableDirective, dtTrigger: Subject<any>): boolean {
    if (dtUpdate) {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        dtTrigger.next(null);
      });
    } else {
      dtTrigger.next(null);
    }

    return true;
  }
}