<form [formGroup]="form">
    <div class="modal-header bg-danger">
        Ammonizione
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="notes">Note</label>
            <textarea class="form-control" id="notes" formControlName="notes"></textarea>
        </div>

    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
        <button type="submit" class="btn btn-info" (click)="save()">Salva</button>
    </div>
</form>