import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { RestService } from 'src/app/utils/services/rest.service';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Sponsorship } from 'src/app/models/sponsorship';
import { ModalSponsorshipComponent } from 'src/app/modals/modal-sponsorship/modal-sponsorship.component';

@Component({
  selector: 'app-sponsorships',
  templateUrl: './sponsorships.component.html',
  styleUrls: ['./sponsorships.component.css']
})
export class SponsorshipsComponent implements OnInit {

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = true;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  form: FormGroup;
  submitted = false;

  sponsors: Sponsorship[];
  isUpdate: boolean;

  constructor(private rest: RestService, private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [2] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.sponsorsList();
  }

  /**
   * Lista dei banner
   */
  sponsorsList() {
    this.isLoading = true;

    this.rest.sponsorshipsList().subscribe(res => {
      this.isLoading = false;

      this.sponsors = res.data.sponsorships;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  add() {
    const modalRef = this.modalService.open(ModalSponsorshipComponent);
    modalRef.result.then((result) => {
      this.toastr.success('Sponsor aggiunto con successo!');
      this.sponsorsList();
    }, (reason) => { });
  }

  edit(item: Sponsorship) {
    const modalRef = this.modalService.open(ModalSponsorshipComponent);
    modalRef.componentInstance.sponsor = item;
    modalRef.result.then((result) => {
      this.toastr.success('Sponsor modificato con successo!');
      this.sponsorsList();
    }, (reason) => { });
  }

  /**
   * Elimina
   */
  delete(item: Sponsorship) {
    this.rest.sponsorshipsDelete(item.sponsorship_id).subscribe(res => {
      this.toastr.success('Sponsor eliminato con successo!');
      this.sponsorsList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}