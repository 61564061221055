<form [formGroup]="form">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-12">
                <label>Filtra per città</label>

                <div class="select2-info">
                    <div class="select2-info">
                        <ng-select formControlName="cities" [items]="cities" bindLabel="city_name" bindValue="city_id"
                            [clearable]="false" [multiple]="true" [placeholder]="'Scegli una o più città'"
                            notFoundText="Nessuna città disponibile" [closeOnSelect]="false">
                            <ng-template *ngIf="cities?.length > 0" ng-header-tmp>
                                <div>
                                    <button type="button" class="btn btn-link" (click)="onSelectAll()">Seleziona
                                        tutti</button>
                                    <button type="button" class="btn btn-link" (click)="onClearAll()">Elimina
                                        tutti</button>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
        <button type="submit" class="btn btn-info" (click)="apply()">Applica</button>
    </div>
</form>