<section class="content-header">
    <div class="container-fluid">
        <h1>Banners per TV</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button class="btn btn-info mb-3" (click)="add()"><i class="fas fa-plus mr-1"></i>
                    Aggiungi</button>

                <div class="card">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Totem associati</th>
                                    <th class="text-center">Schermo 10"</th>
                                    <th class="text-center">Schermo 55"</th>
                                    <th style="width: 20%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of banners">
                                    <td>{{item.name}}</td>
                                    <td>
                                        <span *ngFor="let p of item.places; let isLast=last">{{p.place_name}}{{isLast ? '' : ', '}}</span>
                                    </td>
                                    <td class="text-center">
                                        <i *ngIf="item.type_10 == 1" class="fas fa-check"></i>
                                    </td>
                                    <td class="text-center">
                                        <i *ngIf="item.type_55 == 1" class="fas fa-check"></i>
                                    </td>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="edit(item)"><i class="fas fa-eye mr-1"></i> Visualizza</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo banner ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }" (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>