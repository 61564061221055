import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Admonition } from 'src/app/models/admonition';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-modal-admonition',
  templateUrl: './modal-admonition.component.html',
  styleUrls: ['./modal-admonition.component.css']
})
export class ModalAdmonitionComponent implements OnInit {
  @Input() admonition_id?: number;
  @Input() user_id: number;
  @Input() event_id?: number;
  @Input() notes: string;

  form: FormGroup;
  submitted = false;

  constructor(public activeModal: NgbActiveModal, private rest: RestService, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      notes: new FormControl(this.notes)
    });
  }

  get f() {
    return this.form.controls;
  }

  /**
   * Salvataggio
   */
  save() {
    this.submitted = true;

    if (this.admonition_id != null) {
      this.rest.admonitionEdit(this.admonition_id, this.f.notes.value).subscribe(res => {
        this.toastr.success('Ammonizione effettuata con successo!');
        this.activeModal.close();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.admonitionAdd(this.user_id, this.event_id, this.f.notes.value).subscribe(res => {
        this.toastr.success('Ammonizione effettuata con successo!');
        this.activeModal.close();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }

  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
