<section class="content-header">
    <div class="container-fluid">
        <h1>Classifica</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <div class="card">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">Posizione</th>
                                    <th>Cognome Nome</th>
                                    <th>Nome d'arte</th>
                                    <th>Email</th>
                                    <th>Città</th>
                                    <th class="text-center">Eventi</th>
                                    <th class="text-center">Punti</th>
                                    <th class="text-center">Media Voti</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of users; let i = index">
                                    <td class="align-middle text-center">
                                        <h2><span class="badge badge-info">{{i+1}}°</span></h2>
                                    </td>
                                    <td class="align-middle">{{item.surname}} {{item.name}}</td>
                                    <td class="align-middle">{{item.username}}</td>
                                    <td class="align-middle">{{item.email}}</td>
                                    <td class="align-middle">{{item.city_name}}</td>
                                    <td class="align-middle text-center">{{item.events_count}}</td>
                                    <td class="align-middle text-center">{{item.score}}</td>
                                    <td class="align-middle text-center">
                                        <h2><strong>{{(item.rating? item.rating : 0) | number : '1.2-2'}}</strong></h2>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>