import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/utils/constants';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Pagination } from 'src/app/models/pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ModalCitiesComponent } from 'src/app/modals/modal-cities/modal-cities.component';
import { ModalExportLogComponent } from 'src/app/modals/modal-export-log/modal-export-log.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit, AfterViewInit, OnDestroy {
  dtOptions: any;
  pagination: Pagination = new Pagination;
  globalListenFunc: Function;

  types: any;
  emission_types: any;

  citiesSelected: [];

  columns = {
    0: "e.event_date",
    1: "e.end_date",
    2: "p.place_name",
    3: "c.city_name",
    4: "e.title",
    7: "e.enabled",
    8: "pending_status"
  }

  constructor(
    private rest: RestService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appConstants: AppConstants,
    public auth: AuthenticationService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // tipologie di eventi
    this.types = this.appConstants.EVENT_TYPES;
    this.emission_types = this.appConstants.EMISSION_TYPES;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      stateSave: true,
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      dom: "<'mb-3 text-center'B><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          text: '<span style="display:none" id="export-spinner" class="spinner-border spinner-border-sm mr-1"></span>Esporta Excel',
          className: 'export-button',
          action: () => {
            this.export();
          }
        },
        {
          extend: 'spacer'
        },
        {
          text: '<span style="display:none" id="export-spinner-today" class="spinner-border spinner-border-sm mr-1"></span>Esporta Excel odierni',
          className: 'export-button',
          action: () => {
            this.eventsTodayExportExcel();
          }
        },
        {
          extend: 'spacer'
        },
        {
          text: 'Filtra per Città',
          action: () => {
            const modalRef = this.modalService.open(ModalCitiesComponent);
            modalRef.componentInstance.cities = this.citiesSelected;

            modalRef.result.then((result) => {
              this.citiesSelected = result;

              sessionStorage.setItem('filteredCityIds', JSON.stringify(result));

              $('#table-events').DataTable().ajax.reload();
            }, (reason) => { });
          }
        }
      ],
      ajax: (dataTablesParameters: any, callback) => {
        this.pagination.search = dataTablesParameters.search.value;
        this.pagination.start = dataTablesParameters.start;
        this.pagination.length = dataTablesParameters.length;
        this.pagination.sort_field = this.columns[(dataTablesParameters.order[0].column)];
        this.pagination.sort_type = dataTablesParameters.order[0].dir;

        const savedCityIds = sessionStorage.getItem('filteredCityIds');
        this.citiesSelected = savedCityIds ? JSON.parse(savedCityIds) : [];

        this.rest.eventsList(this.pagination, this.citiesSelected).subscribe(res => {
          callback({
            recordsTotal: res.data.total,
            recordsFiltered: res.data.total,
            data: res.data.events
          });
        }, error => {
          this.toastr.error('Si è verificato un errore, riprova!');
        });
      },
      columnDefs: [
        { type: "date-euro", targets: 0 },
        { orderable: false, targets: [5, 6, 9] }
      ],
      columns: [
        {
          title: 'Data Inizio', data: null, render: function (data, type, full, meta) {
            return moment(data.event_date * 1000).format('DD/MM/YYYY HH:mm');
          }
        },
        {
          title: 'Data Fine', data: null, render: function (data, type, full, meta) {
            return moment(data.end_date * 1000).format('DD/MM/YYYY HH:mm');
          }
        },
        { title: 'Luogo', data: 'place_name' },
        { title: 'Città', data: 'city_name' },
        {
          title: 'Titolo', data: null, render: (data) => {
            if (data.title) return data.title;
            else return this.getTypeTitle(data.type_id).type_name;
          }
        },
        { title: 'Talents', data: 'talents_string' },
        {
          title: 'Emissione Luogo', data: null, render: (data) => {
            return this.getEmission(data.emission).name;
          }
        },
        {
          title: 'Visibile', data: null, render: (data) => {
            if (data.end_date * 1000 < Date.now()) return '<span class="badge badge-pill badge-danger text-uppercase">TERMINATO</span>';
            else if (data.enabled == 0) return '<span class="badge badge-pill badge-danger text-uppercase">NON VISIBILE</span>';
            else if (data.enabled == 1) return '<span class="badge badge-pill badge-success text-uppercase">VISIBILE</span>';
          }
        },
        {
          title: 'Stato', data: null, render: (data) => {
            switch (data.pending_status) {
              case 0: return '<span class="badge badge-pill badge-warning text-uppercase">IN ATTESA</span>';
              case 1: return '<span class="badge badge-pill badge-success text-uppercase">CONFERMATO</span>';
              case 2: return '<span class="badge badge-pill badge-danger text-uppercase">RIFIUTATO</span>';
            }
          }
        },
        {
          className: 'text-right',
          data: null,
          render: function (data, type, full, meta) {
            return '<button class="btn btn-info btn-sm mr-1"" data-id="' + data.event_id + '"> <i class="fas fa-eye mr-1"> </i>Visualizza</button>' +
              '<button class="btn btn-danger btn-sm" data-delete data-id="' + data.event_id + '"><i class="fas fa-trash mr-1"></i>Elimina</button>';
          }
        }
      ]
    };
  }

  ngAfterViewInit(): void {
    this.globalListenFunc = this.renderer.listen('document', 'click', (event) => {
      let eventID = event.target.getAttribute("data-id");

      if (eventID) {
        if (event.target.hasAttribute("data-delete")) {
          this.deletePopup(eventID);
        } else {
          this.router.navigate(['/events/detail', eventID]);
        }
      }
    });
  }

  deletePopup(event_id) {
    Swal.fire({
      icon: 'warning',
      title: 'Elimina',
      text: "Vuoi eliminare questo evento ?",
      showCancelButton: true,
      confirmButtonText: 'Si, elimina!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.delete(event_id);
      }
    })
  }

  getTypeTitle(id) {
    var obj = this.types.find(function (node) {
      return node.type_id == id;
    });

    return obj;
  }

  getEmission(id) {
    var obj = this.emission_types.find(function (node) {
      return node.id == id;
    });
    return obj;
  }

  // Export

  public eventsTodayExportExcel() {
    $('.export-button').prop('disabled', true);
    $('#export-spinner-today').css({ display: 'inline-block' });

    this.rest.eventsTodayExportExcel().subscribe(res => {
      $('.export-button').prop('disabled', false);
      $('#export-spinner-today').css({ display: 'none' });
      this.rest.downloadFile(res, "Eventi", "xlsx");
    }, error => {
      $('.export-button').prop('disabled', false);
      $('#export-spinner-today').css({ display: 'none' });
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  export() {
    const modalRef = this.modalService.open(ModalExportLogComponent);
    modalRef.result.then((result) => {
      $('.export-button').prop('disabled', true);
      $('#export-spinner').css({ display: 'inline-block' });

      this.rest.eventsExport(result.start_date, result.end_date, this.pagination).subscribe(res => {
        $('.export-button').prop('disabled', false);
        $('#export-spinner').css({ display: 'none' });
        this.rest.downloadFile(res, "Eventi", "xlsx");
      }, error => {
        $('.export-button').prop('disabled', false);
        $('#export-spinner').css({ display: 'none' });
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }, (reason) => { });
  }

  /**
  * Elimina
  */
  delete(event_id: number) {
    this.rest.eventDelete(event_id).subscribe(res => {
      $('#table-events').DataTable().ajax.reload();

      this.toastr.success('Evento eliminato con successo!');
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  ngOnDestroy() {
    this.globalListenFunc();
  }
}
