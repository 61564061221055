import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { City } from 'src/app/models/city';
import { Document, DocumentOperation } from 'src/app/models/document';
import { DocumentCity } from 'src/app/models/document_city';
import { Profession } from 'src/app/models/profession';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-talent-document',
  templateUrl: './talent-document.component.html',
  styleUrls: ['./talent-document.component.css']
})
export class TalentDocumentComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElementOperations: DataTableDirective;
  dtOptionsOperations: DataTables.Settings = {};
  dtTriggerOperations: Subject<any> = new Subject();
  isUpdateOperations: boolean;
  isLoadingOperations: boolean = true;

  form: FormGroup;
  submitted = false;
  submitted_access = false;
  saving = false;
  isLoading = false;
  user_id: number;
  city_id: number;

  user: User;
  city: DocumentCity;
  document: Document;
  professions: Profession[];
  operations: DocumentOperation[];

  fileDocumentFront: File = null;
  fileDocumentBack: File = null;
  fileDocumentPermit: File = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rest: RestService,
    private toastr: ToastrService,
    public auth: AuthenticationService,
  ) {
    this.user_id = this.route.snapshot.params['id'];
    this.city_id = this.route.snapshot.params['city_id'];
  }

  ngOnInit(): void {
    this.dtOptionsOperations = {
      columnDefs: [{ orderable: false, targets: [1, 2] }],
      order: [0, "desc"],
      language: { url: "./assets/datatable_ITA.json" },
      destroy: true
    };

    this.form = new FormGroup({
      document_user_type: new FormControl(),
      document_video_url: new FormControl(),
      document_emission: new FormControl(),
      document_emission_2: new FormControl(),
      document_emission_2_description: new FormControl(),
      document_status: new FormControl(0, Validators.required),
      document_status_reason: new FormControl(),
      document_type: new FormControl(),
      document_number: new FormControl(),
      document_start_date: new FormControl(),
      document_end_date: new FormControl(),
      document_permit_type: new FormControl(),
      document_permit_nationality: new FormControl(),
      document_permit_number: new FormControl(),
      document_permit_start_date: new FormControl(),
      document_permit_end_date: new FormControl(),
      professions: new FormControl()
    });

    this.getDocumentDetail();

    this.getOperationsList();
  }

  get f() {
    return this.form.controls;
  }

  getDocumentDetail() {
    this.isLoading = true;

    this.rest.documentsDetail(this.user_id, this.city_id).subscribe(res => {
      this.document = res.data.document;
      this.city = res.data.city;
      this.user = res.data.user;

      if (this.document) {
        this.form.setValue({
          document_user_type: this.document.document_user_type,
          document_video_url: this.document.document_video_url,
          document_status: this.document.document_status,
          document_status_reason: this.document.document_status_reason != null ? this.document.document_status_reason : "",
          document_type: this.document.document_type,
          document_number: this.document.document_number,
          document_start_date: this.document.document_start_date,
          document_end_date: this.document.document_end_date,
          document_emission: (this.document.document_emission != null) ? this.document.document_emission : 1,
          document_emission_2: this.document.document_emission_2,
          document_emission_2_description: this.document.document_emission_2_description,
          document_permit_type: this.document.document_permit_type,
          document_permit_number: this.document.document_permit_number,
          document_permit_nationality: this.document.document_permit_nationality,
          document_permit_start_date: this.document.document_permit_start_date,
          document_permit_end_date: this.document.document_permit_end_date,
          professions: Document.getProfessionIds(this.document.professions)
        })
      }

      this.professionsList();

      this.isLoading = false;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  // Log operazioni sul documento
  getOperationsList() {
    this.isLoadingOperations = true;

    this.rest.documentOperationsList(this.user_id, this.city_id).subscribe(res => {      
      this.isLoadingOperations = false;
      this.operations = res.data.operations;

      if (this.isUpdateOperations) {
        this.rerenderDataTable();
      } else {
        this.isUpdateOperations = true;
        this.dtTriggerOperations.next();
      }
    }, error => {
      this.isLoadingOperations = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }


  // Professions 
  professionsList() {
    this.rest.professionsList(this.user.type, null).subscribe(res => {
      this.professions = res.data.professions;
    });
  }

  // Images
  handleFileInputDocumentFront(files: FileList) {
    if (files && files.length > 0) {
      this.fileDocumentFront = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.document.document_front_url = event.target.result.toString();
      };

      reader.readAsDataURL(this.fileDocumentFront);
    }
  }

  handleFileInputDocumentBack(files: FileList) {
    if (files && files.length > 0) {
      this.fileDocumentBack = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.document.document_back_url = event.target.result.toString();
      };

      reader.readAsDataURL(this.fileDocumentBack);
    }
  }

  handleFileInputDocumentPermit(files: FileList) {
    if (files && files.length > 0) {
      this.fileDocumentPermit = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.document.document_permit_url = event.target.result.toString();
      };

      reader.readAsDataURL(this.fileDocumentPermit);
    }
  }

  // Update document
  save() {
    this.f.document_emission_2_description.setValidators(this.f.document_emission_2.value == 5 ? [Validators.required] : null);
    this.f.document_emission_2_description.updateValueAndValidity();

    this.submitted = true;

    var notEuropean = this.f.document_permit_type.value != 0;

    if (this.form.invalid) {
      return;
    }

    if (this.fileDocumentFront?.size / 1024 / 1024 > 2) {
      this.toastr.error('Immagine (documento fronte) troppo grande!');
      return;
    }
    if (this.fileDocumentBack?.size / 1024 / 1024 > 2) {
      this.toastr.error('Immagine (documento retro) troppo grande!');
      return;
    }
    if (this.fileDocumentPermit?.size / 1024 / 1024 > 2) {
      this.toastr.error('Immagine (permesso di soggiorno / visto) troppo grande!');
      return;
    }

    const formData = new FormData();
    formData.append('user_id', this.user_id.toString());
    formData.append('city_id', this.city_id.toString());
    formData.append("document_status", this.f.document_status.value.toString());

    if (this.f.document_status.value == 2 && this.f.document_status_reason.value) {
      formData.append("document_status_reason", this.f.document_status_reason.value.toString());
    } else {
      formData.append("document_status_reason", "");
    }

    if (this.f.document_user_type.value != null) formData.append("document_user_type", this.f.document_user_type.value);
    if (this.f.document_video_url.value != null) formData.append("document_video_url", this.f.document_video_url.value);
    if (this.f.document_number.value != null) formData.append("document_number", this.f.document_number.value);
    if (this.f.document_emission.value != null) formData.append("document_emission", this.f.document_emission.value);
    if (this.f.document_emission_2.value != null) formData.append("document_emission_2", this.f.document_emission_2.value);
    if (this.f.document_emission_2.value != null && this.f.document_emission_2_description.value != null) formData.append("document_emission_2_description", this.f.document_emission_2_description.value);
    if (this.f.professions.value != null) formData.append("professions", this.f.professions.value.toString());
    if (this.f.document_type.value != null) formData.append("document_type", this.f.document_type.value.toString());
    if (this.f.document_start_date.value != null) formData.append("document_start_date", moment(this.f.document_start_date.value).format('YYYY-MM-DD'));
    if (this.f.document_end_date.value != null) formData.append("document_end_date", moment(this.f.document_end_date.value).format('YYYY-MM-DD'));
    if (this.f.document_permit_type.value != null) formData.append("document_permit_type", this.f.document_permit_type.value.toString());
    if (this.fileDocumentFront != null) formData.append("document_front", this.fileDocumentFront);
    if (this.fileDocumentBack != null) formData.append("document_back", this.fileDocumentBack);

    if (notEuropean && this.f.document_permit_number.value != null) formData.append("document_permit_number", this.f.document_permit_number.value);
    if (notEuropean && this.f.document_permit_nationality.value != null) formData.append("document_permit_nationality", this.f.document_permit_nationality.value);
    if (notEuropean && this.f.document_permit_start_date.value != null) formData.append("document_permit_start_date", moment(this.f.document_permit_start_date.value).format('YYYY-MM-DD'));
    if (notEuropean && this.f.document_permit_end_date.value != null) formData.append("document_permit_end_date", moment(this.f.document_permit_end_date.value).format('YYYY-MM-DD'));
    if (notEuropean && this.fileDocumentPermit != null) formData.append("document_permit", this.fileDocumentPermit);

    this.saving = true;

    this.rest.documentEdit(formData).subscribe(res => {
      this.saving = false;
      this.toastr.success('Documento aggiornato con successo!');
      this.getOperationsList();
    }, error => {
      this.saving = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElementOperations.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTriggerOperations.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTriggerOperations.unsubscribe();
  }
}
