import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppConstants {
    public EVENT_TYPES = [
        { type_id: 1, type_name: "Special Stage" },
        { type_id: 2, type_name: "Special Cook" },
        { type_id: 3, type_name: "Open Stage" },
        { type_id: 4, type_name: "Altro" }
    ]

    public EMISSION_TYPES = [
        { id: 0, name: "Bassa emissione" },
        { id: 1, name: "Media emissione" },
        { id: 2, name: "Alta emissione" },
        { id: 3, name: "Nessuna emissione" },
        { id: 4, name: "Vocale" },
        { id: 5, name: "Acustica" }
    ]
}