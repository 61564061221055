import { Profession } from "./profession";

export class Document {
    user_id: number;
    city_id: number;

    document_user_type?: number;
    document_video_url: string;
    document_status?: number;
    document_status_reason?: string;
    document_type?: number;
    document_number?: string;
    document_start_date?: number;
    document_end_date?: number;
    document_emission?: number;
    document_emission_2?: number;
    document_emission_2_description?: string;
    document_permit_type?: number
    document_permit_start_date?: number
    document_permit_end_date?: number
    document_permit_number?: string
    document_permit_nationality?: string

    professions: Profession[];

    document_front_url: string;
    document_back_url: string;
    document_permit_url: string;

    public static getProfessionIds(professions: Profession[]) {
        var ids = [];
        for (let p of professions) {
            ids.push(p.profession_id);
        }
        return ids;
    }
}