<aside #mainSidebar class="main-sidebar sidebar-dark-info elevation-4">
  <!-- Brand Logo -->
  <div class="sidebar-light-info text-center">
    <a href="#" class=" brand-link logo-switch">
      <img src="assets/img/ic_logo.png" alt="OfficineBuone Logo Small" class="brand-image-xl logo-xs">
      <img src="assets/img/logo.png" alt="OfficineBuone Logo Large" class="brand-image-xs logo-xl"
        style="left: 43px; max-height: 40px;">
    </a>
  </div>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

        <li class="nav-item" *ngIf="role == 0 || role == 1 || role == 2 || role == 3">
          <a [routerLinkActive]="['active']" [routerLink]="['/dashboard']" class="nav-link">
            <i class="nav-icon fas fa-home"></i>
            <p>Dashboard</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0 || role == 1 || role == 2 || role == 3">
          <a [routerLinkActive]="['active']" [routerLink]="['/events']" class="nav-link">
            <i class="nav-icon fas fa-calendar-alt"></i>
            <p>Eventi</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0 || role == 1 || role == 2 || role == 3">
          <a [routerLinkActive]="['active']" [routerLink]="['/events-past']" class="nav-link">
            <i class="nav-icon fas fa-calendar-alt"></i>
            <p>Storico eventi</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0">
          <a [routerLinkActive]="['active']" [routerLink]="['/managers']" class="nav-link">
            <i class="nav-icon fas fa-user-tie"></i>
            <p>Organizzatori</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0">
          <a [routerLinkActive]="['active']" [routerLink]="['/users']" class="nav-link">
            <i class="nav-icon fas fa-user"></i>
            <p>Utenti</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0 || role == 1 || role == 2 || role == 3">
          <a [routerLinkActive]="['active']" [routerLink]="['/talents']" class="nav-link">
            <i class="nav-icon fas fa-user-friends"></i>
            <p>Talenti</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0 || role == 1 || role == 2 || role == 3">
          <a [routerLinkActive]="['active']" [routerLink]="['/groups']" class="nav-link">
            <i class="nav-icon fas fa-users"></i>
            <p>Gruppi</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0 || role == 1 || role == 2">
          <a [routerLinkActive]="['active']" [routerLink]="['/cities']" class="nav-link">
            <i class="nav-icon fas fa-map-marker-alt"></i>
            <p>Città</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0 || role == 1 || role == 2 || role == 3">
          <a [routerLinkActive]="['active']" [routerLink]="['/places']" class="nav-link">
            <i class="nav-icon fas fa-building"></i>
            <p>Luoghi</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0">
          <a [routerLinkActive]="['active']" [routerLink]="['/awards']" class="nav-link">
            <i class="nav-icon fas fa-award"></i>
            <p>Awards</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0">
          <a [routerLinkActive]="['active']" [routerLink]="['/ranking']" class="nav-link">
            <i class="nav-icon fas fa-trophy"></i>
            <p>Classifica</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0">
          <a [routerLinkActive]="['active']" [routerLink]="['/notifications']" class="nav-link">
            <i class="nav-icon fas fa-bullhorn"></i>
            <p>Notifiche</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0">
          <a [routerLinkActive]="['active']" [routerLink]="['/sponsors']" class="nav-link">
            <i class="nav-icon fas fa-handshake"></i>
            <p>Banners</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0">
          <a [routerLinkActive]="['active']" [routerLink]="['/sponsorships']" class="nav-link">
            <i class="nav-icon fas fa-handshake"></i>
            <p>Sponsor TV</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0">
          <a [routerLinkActive]="['active']" [routerLink]="['/banners-tv']" class="nav-link">
            <i class="nav-icon fas fa-tv"></i>
            <p>Banners TV</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0">
          <a [routerLinkActive]="['active']" [routerLink]="['/musical-genres']" class="nav-link">
            <i class="nav-icon fas fa-music"></i>
            <p>Generi Musicali</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role == 0 || role == 1 || role == 2">
          <a [routerLinkActive]="['active']" [routerLink]="['/talent-absences']" class="nav-link">
            <i class="nav-icon fas fa-theater-masks"></i>
            <p>Presenze/Assenze</p>
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>