<form [formGroup]="form">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-12 col-lg-6">
                <label for="start_date">Data di inizio</label>
                <input [owlDateTime]="dt_start_date" [owlDateTimeTrigger]="dt_start_date" type="text"
                    class="form-control" id="start_date" formControlName="start_date" readonly>
                <owl-date-time [pickerType]="pickerType" #dt_start_date></owl-date-time>
            </div>

            <div class="form-group col-12 col-lg-6">
                <label for="end_date">Data di fine</label>
                <input [owlDateTime]="dt_end_date" [owlDateTimeTrigger]="dt_end_date" type="text" class="form-control"
                    id="end_date" formControlName="end_date" readonly>
                <owl-date-time [pickerType]="pickerType" #dt_end_date></owl-date-time>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
        <button type="submit" class="btn btn-info" (click)="apply()">Applica</button>
    </div>
</form>