import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { AuthenticationService } from '../utils/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-verify-registration',
  templateUrl: './verify-registration.component.html',
  styleUrls: ['./verify-registration.component.css']
})
export class VerifyRegistrationComponent implements OnInit {
  jwt_token: string;
  message_error: string;
  message_success: string;
  isLoading: boolean = true;

  constructor(private route: ActivatedRoute, private authenticationService: AuthenticationService, private router: Router, private renderer: Renderer2) {
    this.route.queryParams.subscribe(params => {
      this.jwt_token = params['token'];
    });
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'login-page');

    if (this.jwt_token) {      
      this.authenticationService.verifyEmail(this.jwt_token).subscribe(res => {        
        this.isLoading = false;
        this.message_success = "Registrazione completata con successo! Puoi effettuare il login in app!"
      }, error => {        
        this.isLoading = false;
        this.message_error = "Si è verificato un errore!"
      });
    } else {
      this.isLoading = false;
      this.message_error = "Si è verificato un errore!"
    }
  }
}
