<section class="content-header">
    <div class="container-fluid">
        <h1>Generi Musicali</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button class="btn btn-info mb-3" (click)="edit(null)"><i class="fas fa-plus mr-1"></i>
                    Aggiungi</button>

                <div class="card">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Generi Musicali</th>
                                    <th style="width: 20%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of musical_genres">
                                    <td>{{item.name}}</td>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="edit(item)"><i
                                                class="fas fa-eye mr-1"></i> Visualizza</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo genere musicale ?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal add/edit -->
<div class="modal fade" id="modal-default" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="form">
                <div class="modal-body">
                    <div>
                        <input formControlName="city_id" type="hidden">

                        <label>Genere musicale</label>
                        <input formControlName="name" type="text" class="form-control"
                            placeholder="Inserici il nome del genere musicale"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                        <div *ngIf="f.name.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                    </div>

                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button type="submit" class="btn btn-info" (click)="save()">Salva</button>
                </div>
            </form>

        </div>
    </div>
</div>

<!-- <swal #toast [swalOptions]="alertOption"></swal> -->