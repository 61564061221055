import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { AppConstants } from 'src/app/utils/constants';
import { Router } from '@angular/router';
import { Pagination } from 'src/app/models/pagination';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtOptions: any;

  users: User[] = [];
  pagination: Pagination = new Pagination;
  globalListenFunc: Function;

  columns = {
    0: "surname",
    1: "username",
    2: "email",
    3: "newsletter",
    4: "score",
    5: "enabled",
  }

  constructor(private rest: RestService, private renderer: Renderer2, private toastr: ToastrService, private appConstants: AppConstants, private router: Router) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      stateSave: true,
      order: [0, "asc"],
      columnDefs: [
        { orderable: false, targets: [6] }
      ],
      language: {
        url: "./assets/datatable_ITA.json"
      },

      dom: "<'mb-3 text-center'B><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          text: 'Esporta Excel',
          className: 'export-excel',
          action: (e, dt, node, config) => {
            this.export();
          }
        }
      ],
      ajax: (dataTablesParameters: any, callback) => {
        this.pagination.search = dataTablesParameters.search.value;
        this.pagination.start = dataTablesParameters.start;
        this.pagination.length = dataTablesParameters.length;
        this.pagination.sort_field = this.columns[(dataTablesParameters.order[0].column)];
        this.pagination.sort_type = dataTablesParameters.order[0].dir;

        this.rest.usersList(this.pagination).subscribe(res => {
          callback({
            recordsTotal: res.data.total,
            recordsFiltered: res.data.total,
            data: res.data.users
          });
        }, error => {
          this.toastr.error('Si è verificato un errore, riprova!');
        });
      },

      columns: [
        {
          title: 'Cognome e Nome', data: null, render: function (data, type, full, meta) {
            return data.surname + " " + data.name;
          }
        },
        { title: 'Username', data: 'username' },
        { title: 'Email', data: 'email' },
        {
          title: 'Newsletter', data: null, render: function (data, type, full, meta) {
            return data.newsletter == 1 ? 'Si' : 'No';
          }
        },
        { title: 'Punti', data: 'score' },
        {
          title: 'Stato', data: null, render: function (data, type, full, meta) {
            switch (data.enabled) {
              case 0: return '<span class="badge badge-pill badge-danger text-uppercase">Non attivo</span>'
              case 1: return '<span class="badge badge-pill badge-success text-uppercase">Attivo</span>'
            }
          }
        },
        {
          className: 'text-right',
          data: null,
          render: function (data, type, full, meta) {
            return '<button class="btn btn-info btn-sm" data-id="' + data.user_id + '"> <i class="fas fa-eye mr-1" data-id="' + data.user_id + '"> </i> Visualizza</button >';
          }
        }
      ],
      drawCallback: () => {
        $('.btn').on('click', (event) => {
          if (event.target.hasAttribute("data-id")) {
            event.preventDefault();
            this.router.navigate(['/users/detail', event.target.getAttribute("data-id")]);
          }
        });
      }
    };
  };

  export() {
    this.datatableElement['dt'].button('.export-excel').disable();

    this.rest.usersExport(this.pagination).subscribe(res => {
      this.rest.downloadFile(res, "Utenti", "xlsx");
      this.datatableElement['dt'].button('.export-excel').enable();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
      this.datatableElement['dt'].button('.export-excel').enable();
    });
  }
}
