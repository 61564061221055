import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { RestService } from 'src/app/utils/services/rest.service';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { City } from 'src/app/models/city';
import { Award } from 'src/app/models/award';
declare var $: any;

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.css']
})
export class AwardsComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = true;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  form: FormGroup;
  submitted = false;

  awards: Award[];
  isUpdate: boolean;
  fileCover: File = null;

  toolbar = [
    ['style', ['bold', 'italic', 'underline', 'clear']],
    ['font', ['strikethrough']],
    ['fontsize', ['fontsize']],
    ['color', ['color']],
    ['para', ['ul', 'ol', 'paragraph']]
  ];

  constructor(private rest: RestService, private toastr: ToastrService) { }

  ngOnInit(): void {
    $('#email_user').summernote({
      toolbar: this.toolbar,
      height: 150
    });
    $('#email_talent').summernote({
      toolbar: this.toolbar,
      height: 150
    });

    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [1, 2] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = new FormGroup({
      award_id: new FormControl(null),
      points: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]\d*$/), Validators.min(0)]),
      title: new FormControl(null, Validators.required),
      image_url: new FormControl(null, Validators.required),
      email_user: new FormControl(null), 
      email_talent: new FormControl(null)
    });

    this.awardsList();
  }

  get f() {
    return this.form.controls;
  }

  handleFileInputCover(files: FileList) {
    if (files && files.length > 0) {
      this.fileCover = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.f.image_url.setValue(event.target.result);
      };

      reader.readAsDataURL(this.fileCover);
    }
  }

  /**
   * Lista dei punti
   */
  awardsList() {
    this.isLoading = true;

    this.rest.awardsList().subscribe(res => {
      this.isLoading = false;

      this.awards = res.data.awards;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Popup aggiunta/modifica
   */
  edit(item: Award) {
    this.submitted = false;

    if (item) {
      this.f.award_id.setValue(item.award_id);
      this.f.points.setValue(item.points);
      this.f.title.setValue(item.title);
      this.f.image_url.setValue(item.image_url);
      $('#email_user').summernote('code', item.email_user);
      $('#email_talent').summernote('code', item.email_talent);
    } else {
      $('#email_user').summernote('reset');
      $('#email_talent').summernote('reset');
      this.form.reset();
    }
    $("#modal-default").modal('show');
  }

  /**
   * Elimina
   */
  delete(item: Award) {
    this.rest.awardDelete(item.award_id).subscribe(res => {
      this.toastr.success('Punteggio eliminato con successo!');
      this.awardsList();
    }, error => {
      this.toastr.error('Si è verificato un errore!');
    });
  }

  /**
   * Salvataggio
   */
  save() {
    this.submitted = true;

    // verifica se email user è vuota
    if ($('#email_user').summernote('isEmpty')) {
      this.f.email_user.setErrors({ 'required': true })
    } else {
      this.f.email_user.setErrors(null)
    }

    // verifica se email talent è vuota
    if ($('#email_talent').summernote('isEmpty')) {
      this.f.email_talent.setErrors({ 'required': true })
    } else {
      this.f.email_talent.setErrors(null)
    }

    if (this.form.invalid) {
      return;
    }

    var awardID = this.f.award_id.value;
    var title = this.f.title.value;
    var points = this.f.points.value;
    var email_user = $('#email_user').summernote('code');
    var email_talent = $('#email_talent').summernote('code');

    const formData = new FormData();
    formData.append("title", title);
    formData.append('points', points);
    formData.append('email_user', email_user);
    formData.append('email_talent', email_talent);

    if (!awardID && !this.fileCover) {
      this.toastr.error('Inserire un\'immagine!');
      return;
    }

    if (!awardID && this.fileCover?.size / 1024 / 1024 > 1) {
      this.toastr.error('Immagine troppo grande!');
      return;
    }

    if (this.fileCover) formData.append("file", this.fileCover);

    if (awardID) {
      //edit
      formData.append('award_id', awardID);

      this.rest.awardEdit(formData).subscribe(res => {
        $("#modal-default").modal('hide');
        this.toastr.success('Punteggio modificato con successo!');
        this.awardsList();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      // nuovo award
      this.rest.awardAdd(formData).subscribe(res => {
        $("#modal-default").modal('hide');
        this.toastr.success('Punteggio aggiunto con successo!');
        this.awardsList();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }

  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    $('#email_user').summernote('destroy');
    $('#email_talent').summernote('destroy');
    this.dtTrigger.unsubscribe();
  }
}
