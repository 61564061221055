import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Sponsorship } from 'src/app/models/sponsorship';
import { RestService } from 'src/app/utils/services/rest.service';
declare var $: any;

@Component({
  selector: 'app-modal-sponsorship',
  templateUrl: './modal-sponsorship.component.html',
  styleUrls: ['./modal-sponsorship.component.css']
})
export class ModalSponsorshipComponent implements OnInit {

  @Input() sponsor: Sponsorship;

  submitted = false;
  form: FormGroup;
  fileCover: File = null;
  loading = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private rest: RestService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      image_url: [this.sponsor?.image_url],
      name: [this.sponsor?.name, Validators.required],
      places: this.sponsor?.places
    });

    $('#places').select2({
      allowClear: true
    });

    this.rest.placesLiteList().subscribe(res => {
      for (let place of res.data.places) {
        var data = { id: place.place_id, text: place.place_name };
        var newOption = new Option(data.text, data.id.toString(), false, false);
        $('#places').append(newOption).trigger('change');
      }

      $('#places').val(this.sponsor?.places.map(({ place_id }) => place_id)).trigger("change");
    }, error => {
    });
  }

  get f() {
    return this.form.controls;
  }

  handleFileInputCover(files: FileList) {
    if (files && files.length > 0) {
      this.fileCover = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.f.image_url.setValue(event.target.result);
      };

      reader.readAsDataURL(this.fileCover);
    }
  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (!this.sponsor?.sponsorship_id && !this.fileCover) {
      this.toastr.error('Inserire un\'immagine!');
      return;
    }

    if (!this.sponsor?.sponsorship_id && this.fileCover?.size / 1024 / 1024 > 1) {
      this.toastr.error('Immagine troppo grande!');
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append('name', this.f.name.value);
    formData.append("file", this.fileCover);

    var placesSelected = $("#places").select2('data');
    var placesID = [];
    for (let p of placesSelected) {
      placesID.push(p.id);
    }
    formData.append("places", placesID.toString());

    if (this.sponsor?.sponsorship_id) {
      formData.append('sponsorship_id', this.sponsor?.sponsorship_id.toString());

      this.rest.sponsorshipsEdit(formData).subscribe((result) => {
        this.loading = false;
        this.activeModal.close();
      }, error => {
        this.loading = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.sponsorshipsAdd(formData).subscribe((result) => {
        this.loading = false;
        this.activeModal.close();
      }, error => {
        this.loading = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
