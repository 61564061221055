<div class="modal-body">
    <h3 class="text-center">Scegli i membri del gruppo</h3>
    <div class="row">
        <div class="col-sm-12">
            <form [formGroup]="form">
                <ng-select [items]="users" bindLabel="username" bindValue="user_id" formControlName="users" placeholder="Scegli i membri del gruppo"
                    notFoundText="Nessun membro disponibile" [virtualScroll]=true>
                </ng-select>

            </form>
        </div>
    </div>
</div>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
    <button type="submit" class="btn btn-info" (click)="applyFilter()">Salva</button>
</div>