import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { RestService } from 'src/app/utils/services/rest.service';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalBannerTvComponent } from 'src/app/modals/modal-banner-tv/modal-banner-tv.component';
import { BannerTv } from 'src/app/models/banner-tv';

@Component({
  selector: 'app-banners-tv',
  templateUrl: './banners-tv.component.html',
  styleUrls: ['./banners-tv.component.css']
})
export class BannersTvComponent implements OnInit {

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = true;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  form: FormGroup;
  submitted = false;

  banners: BannerTv[];
  isUpdate: boolean;

  constructor(private rest: RestService, private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [4] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.bannersList();
  }

  /**
   * Lista dei banner
   */
   bannersList() {
    this.isLoading = true;

    this.rest.bannersTvList().subscribe(res => {
      this.isLoading = false;

      this.banners = res.data.banners_tv;      
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  add() {
    const modalRef = this.modalService.open(ModalBannerTvComponent);
    modalRef.result.then((result) => {
      this.toastr.success('Banner aggiunto con successo!');
      this.bannersList();
    }, (reason) => { });
  }

  edit(item: BannerTv) {
    const modalRef = this.modalService.open(ModalBannerTvComponent);
    modalRef.componentInstance.banner = item;
    modalRef.result.then((result) => {
      this.toastr.success('Banner modificato con successo!');
      this.bannersList();
    }, (reason) => { });
  }

  /**
   * Elimina
   */
  delete(item: BannerTv) {
    this.rest.bannersTvDelete(item.banner_tv_id).subscribe(res => {
      this.toastr.success('Banner eliminato con successo!');
      this.bannersList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}