import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-modal-export-log',
  templateUrl: './modal-export-log.component.html',
  styleUrls: ['./modal-export-log.component.css']
})
export class ModalExportLogComponent implements OnInit {

  form: FormGroup;
  pickerType: string = 'calendar';

  constructor(
    public activeModal: NgbActiveModal,
    private rest: RestService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      start_date: new FormControl(null),
      end_date: new FormControl(null),
    });
  }

  get f() {
    return this.form.controls;
  }

  apply() {
    var start_date = moment(this.f.start_date.value).isValid() ? moment(this.f.start_date.value).unix() : null;
    var end_date = moment(this.f.end_date.value).isValid() ? moment(this.f.end_date.value).unix() : null;

    this.activeModal.close({ start_date, end_date })
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
