import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { ManagersComponent } from './pages/managers/managers.component';
import { CitiesComponent } from './pages/cities/cities.component';
import { CityDetailComponent } from './pages/cities/city-detail/city-detail.component';
import { PlacesComponent } from './pages/places/places.component';
import { AwardsComponent } from './pages/awards/awards.component';
import { UsersComponent } from './pages/users/users.component';
import { TalentsComponent } from './pages/talents/talents.component';
import { UserDetailComponent } from './pages/users/user-detail/user-detail.component';
import { TalentDetailComponent } from './pages/talents/talent-detail/talent-detail.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { EventsComponent } from './pages/events/events.component';
import { EventDetailComponent } from './pages/events/event-detail/event-detail.component';
import { RankingComponent } from './pages/ranking/ranking.component';
import { VerifyRegistrationComponent } from './verify-registration/verify-registration.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PlaceDetailComponent } from './pages/places/place-detail/place-detail.component';
import { SponsorsComponent } from './pages/sponsors/sponsors.component';
import { SponsorshipsComponent } from './pages/sponsorships/sponsorships.component';
import { BannersTvComponent } from './pages/banners-tv/banners-tv.component';
import { MusicalGenresComponent } from './pages/musical_genres/musical_genres.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EventsPastComponent } from './pages/events_past/events_past.component';
import { TalentDocumentComponent } from './pages/talents/talent-document/talent-document.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { GroupDetailComponent } from './pages/groups/group-detail/group-detail.component';
import { GroupDocumentComponent } from './pages/groups/group-document/group-document.component';
import { TalentAbsencesComponent } from './pages/talent-absences/talent-absences.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'events', component: EventsComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'events-past', component: EventsPastComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'events/new', component: EventDetailComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'events/detail/:id', component: EventDetailComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'managers', component: ManagersComponent, data: { roles: [0] } },
      { path: 'cities', component: CitiesComponent, data: { roles: [0, 1, 2] } },
      { path: 'cities/new', component: CityDetailComponent, data: { roles: [0] } },
      { path: 'cities/detail/:id', component: CityDetailComponent, data: { roles: [0, 1, 2] } },
      { path: 'places', component: PlacesComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'places/new', component: PlaceDetailComponent, data: { roles: [0, 2] } },
      { path: 'places/detail/:id', component: PlaceDetailComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'awards', component: AwardsComponent, data: { roles: [0] } },
      { path: 'talents', component: TalentsComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'talents/new', component: TalentDetailComponent, data: { roles: [0, 2, 3] } },
      { path: 'talents/detail/:id', component: TalentDetailComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'talents/detail/:id/city/:city_id', component: TalentDocumentComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'users', component: UsersComponent, data: { roles: [0] } },
      { path: 'users/detail/:id', component: UserDetailComponent, data: { roles: [0] } },
      { path: 'notifications', component: NotificationsComponent, data: { roles: [0] } },
      { path: 'ranking', component: RankingComponent, data: { roles: [0] } },
      { path: 'sponsors', component: SponsorsComponent, data: { roles: [0] } },
      { path: 'sponsorships', component: SponsorshipsComponent, data: { roles: [0] } },
      { path: 'banners-tv', component: BannersTvComponent, data: { roles: [0] } },
      { path: 'musical-genres', component: MusicalGenresComponent, data: { roles: [0] } },
      { path: 'groups', component: GroupsComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'groups/detail/:id', component: GroupDetailComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'groups/detail/:id/city/:city_id', component: GroupDocumentComponent, data: { roles: [0, 1, 2, 3] } },
      { path: 'talent-absences', component: TalentAbsencesComponent, data: { roles: [0, 1, 2] } },
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'verify_email', component: VerifyRegistrationComponent },
  { path: 'change_password', component: ChangePasswordComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }