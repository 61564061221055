<section class="content-header">
    <div class="container-fluid">
        <h1>Scheda Luogo</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <form [formGroup]="form">
                    <div class="card card-info">
                        <div class="card-header text-uppercase">
                            Informazioni
                        </div>

                        <div class="card-body">
                            <div class="form-group col-12 text-center">
                                <div class="text-center mb-3">
                                    <img height="250"
                                        src="{{f.cover_url.value ? f.cover_url.value :'/assets/img/no-image.png'}}"
                                        alt="Cover">
                                    <div>
                                        <div *ngIf="fileCover"><small>Dimensione: {{fileCover?.size / 1024 / 1024 |
                                                number : '1.2-2'}} MB</small></div>
                                        <small>L'immagine non deve superare 2MB</small>
                                    </div>
                                </div>

                                <div>
                                    <input #img_cover hidden="true" type="file" onclick="this.value=null"
                                        (change)="handleFileInputCover($event.target.files)" accept=".jpg" />
                                    <button type="button" class="btn btn-info" (click)="img_cover.click()">Scegli
                                        immagine di copertina</button>
                                </div>

                                <div *ngIf="f.cover_url.value">
                                    <button type="button" class="btn btn-danger mt-2"
                                        (click)="removeCover()">Elimina</button>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Nome</label>
                                <input formControlName="place_name" type="text" class="form-control"
                                    placeholder="Inserici il nome di un luogo"
                                    [ngClass]="{ 'is-invalid': submitted && f.place_name.errors }" />
                                <div *ngIf="f.place_name.errors?.required" class="invalid-feedback">Campo obbligatorio
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="description_en">Descrizione</label>

                                <nav>
                                    <ul class="nav nav-tabs">
                                        <li class="nav-item"><a class="nav-link active" href="#tab_description_it"
                                                data-toggle="tab">Italiano</a></li>
                                        <li class="nav-item"><a class="nav-link" href="#tab_description_en"
                                                data-toggle="tab">Inglese</a></li>
                                        <li class="nav-item"><a class="nav-link" href="#tab_description_pt"
                                                data-toggle="tab">Portoghese</a></li>
                                        <li class="nav-item"><a class="nav-link" href="#tab_description_es"
                                                data-toggle="tab">Spagnolo</a></li>
                                    </ul>
                                </nav>

                                <div class="tab-content" id="nav-tabContent">
                                    <div class="tab-pane active" id="tab_description_it">
                                        <textarea class="form-control" id="description" formControlName="description"
                                            rows="5"></textarea>
                                    </div>
                                    <div class="tab-pane" id="tab_description_en">
                                        <textarea class="form-control" id="description_en"
                                            formControlName="description_en" rows="5"></textarea>
                                    </div>
                                    <div class="tab-pane" id="tab_description_pt">
                                        <textarea class="form-control" id="description_pt"
                                            formControlName="description_pt" rows="5"></textarea>
                                    </div>
                                    <div class="tab-pane" id="tab_description_es">
                                        <textarea class="form-control" id="description_es"
                                            formControlName="description_es" rows="5"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Tipologia</label>
                                <select formControlName="type" (change)="typeChange()" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                                    <option [ngValue]="null" disabled>Scegli una Tipologia</option>
                                    <option *ngFor="let d of types; let i = index;" [ngValue]="d.id">
                                        {{d.title}}
                                    </option>
                                </select>
                                <div *ngIf="f.type.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                            </div>

                            <div class="row form-group">
                                <div class="col-6">
                                    <label>Città</label>
                                    <select formControlName="city" (change)="cityChange()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                                        <option [ngValue]="null" disabled>Scegli una Città</option>
                                        <option *ngFor="let d of cities; let i = index;" [ngValue]="d.city_id">
                                            {{d.city_name}}
                                        </option>
                                    </select>
                                    <div *ngIf="f.city.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label>Area</label>
                                    <select [attr.disabled]="areas.length == 0 ? 'disabled' : null"
                                        formControlName="area" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.area.errors }">
                                        <option [ngValue]="null" disabled>Scegli una Area</option>
                                        <option *ngFor="let d of areas; let i = index;" [ngValue]="d.area_id">{{d.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="f.area.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-6">
                                    <label>Indirizzo</label>
                                    <input [attr.disabled]="f.type.value == 3 ? 'disabled' : null"
                                        formControlName="address" type="text" class="form-control"
                                        placeholder="Inserici un indirizzo"
                                        [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                                    <div *ngIf="f.address.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>
                                <div class="col-2">
                                    <label>Latitudine</label>
                                    <input [attr.disabled]="f.type.value == 3 ? 'disabled' : null" formControlName="lat"
                                        type="number" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.lat.errors }" />
                                    <div *ngIf="f.lat.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>
                                <div class="col-2">
                                    <label>Longitudine</label>
                                    <input [attr.disabled]="f.type.value == 3 ? 'disabled' : null" formControlName="lon"
                                        type="number" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.lon.errors }" />
                                    <div *ngIf="f.lon.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>
                                <div class="col-2">
                                    <label>Coefficiente viario</label>
                                    <div class="input-group">
                                        <input [attr.disabled]="f.type.value != 2 ? 'disabled' : null"
                                            formControlName="road_coefficient" type="number" class="form-control"
                                            placeholder="Inserisci un valore" min="0"
                                            [ngClass]="{ 'is-invalid': submitted && f.road_coefficient.errors }" />
                                        <div *ngIf="f.road_coefficient.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                        <div *ngIf="f.road_coefficient.errors?.min" class="invalid-feedback">Valore
                                            minimo 0</div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="f.type.value == 3" class="row form-group">
                                <div class="col-6">
                                    <label>Link PDF</label>
                                    <input formControlName="pdf_url" type="text" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.pdf_url.errors }" />
                                    <div *ngIf="f.pdf_url.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label>Link Geoportale</label>
                                    <input formControlName="geoportal_url" type="text" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.geoportal_url.errors }" />
                                    <div *ngIf="f.geoportal_url.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row form-group">
                                <div class="col-12">
                                    <label>Periferiche Sclak</label>
                                    <select formControlName="peripheral" class="form-control"
                                        [attr.disabled]="f.type.value === 1 ? null : 'disabled'"
                                        [ngClass]="{ 'is-invalid': submitted && f.peripheral.errors }">
                                        <option [ngValue]="null" disabled>Scegli una periferica SCLAK</option>
                                        <option *ngFor="let p of peripherals; let i = index;"
                                            [ngValue]="p.peripheral_id">
                                            {{p.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="f.peripheral.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-6">
                                    <label>Sensore G-Move</label>
                                    <select [attr.disabled]="f.type.value == 3 ? 'disabled' : null"
                                        formControlName="gmove_locationid" class="form-control">
                                        <option [ngValue]="null">Nessuno</option>
                                        <option *ngFor="let p of sensorsGmove; let i = index;" [ngValue]="p.locationid">
                                            {{p.locationid}}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-6">
                                    <label>Sensore Wiseair (link dashboard)</label>
                                    <input [attr.disabled]="f.type.value == 3 ? 'disabled' : null"
                                        formControlName="wiseair_url" type="text" class="form-control"
                                        placeholder="Inserisci un link" />
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-3">
                                    <label>Colore (es. #FFFFFF)</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"
                                                [style.background-color]="tint_color.value"></span>
                                        </div>
                                        <input #tint_color formControlName="tint_color" type="text" class="form-control"
                                            placeholder="Inserisci un codice"
                                            [ngClass]="{ 'is-invalid': submitted && f.tint_color.errors }"
                                            (input)="tint_color.value = tint_color.value.toUpperCase()" />
                                        <div *ngIf="f.tint_color.errors?.pattern" class="invalid-feedback">Codice colore
                                            non valido</div>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <label>Timer Banner schermo 10"</label>
                                    <div class="input-group">
                                        <input [attr.disabled]="f.type.value == 3 ? 'disabled' : null"
                                            formControlName="timer_tv_10" type="number" class="form-control"
                                            placeholder="Inserisci un valore" />
                                        <div class="input-group-append">
                                            <span class="input-group-text">sec</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <label>Timer Banner schermo 55"</label>
                                    <div class="input-group">
                                        <input [attr.disabled]="f.type.value == 3 ? 'disabled' : null"
                                            formControlName="timer_tv_55" type="number" class="form-control"
                                            placeholder="Inserisci un valore" />
                                        <div class="input-group-append">
                                            <span class="input-group-text">sec</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6 form-group">
                                    <label>Giorni di attesa per ricandidarsi in questo Luogo</label>
                                    <div class="input-group">
                                        <input formControlName="days_between_user_events" type="number"
                                            class="form-control" placeholder="Inserisci un valore" min="0"
                                            [ngClass]="{ 'is-invalid': submitted && f.days_between_user_events.errors }" />
                                        <div class="input-group-append">
                                            <span class="input-group-text">giorni</span>
                                        </div>
                                        <div *ngIf="f.days_between_user_events.errors?.required"
                                            class="invalid-feedback">Campo obbligatorio</div>
                                        <div *ngIf="f.days_between_user_events.errors?.min" class="invalid-feedback">
                                            Valore minimo 0</div>
                                    </div>
                                </div>
                                <div class="col-6 form-group">
                                    <label>Emissioni</label>
                                    <select formControlName="emission" class="form-control">
                                        <option [ngValue]="3">Nessuna</option>
                                        <option [ngValue]="4">Vocale</option>
                                        <option [ngValue]="0">Bassa</option>
                                        <option [ngValue]="1">Media</option>
                                        <option [ngValue]="2">Alta</option>
                                        <option [ngValue]="5">Acustica</option>
                                    </select>
                                </div>
                                <div class="col-6 form-group">
                                    <label>Numero massimo di prenotazioni al mese per singolo Talent</label>
                                    <input formControlName="talent_month_events_limit" type="number"
                                        class="form-control" placeholder="Inserisci un valore" min="0"
                                        [ngClass]="{ 'is-invalid': submitted && f.talent_month_events_limit.errors }" />

                                    <div *ngIf="f.talent_month_events_limit.errors?.required" class="invalid-feedback">
                                        Campo obbligatorio</div>
                                    <div *ngIf="f.talent_month_events_limit.errors?.min" class="invalid-feedback">
                                        Valore minimo 0</div>
                                </div>
                            </div>

                            <div *ngIf="f.type.value == 2 || f.type.value == 3" class="row form-group">
                                <div class="col-6">
                                    <label>Possono prenotarsi gli utenti di tipo Espressione</label>
                                    <select formControlName="talent" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>
                                <div class="col-6">
                                    <label>Possono prenotarsi gli utenti di tipo Mestiere</label>
                                    <select formControlName="profession" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>
                            </div>

                            <div *ngIf="f.type.value != 2" class="row">
                                <div class="col-6 form-group">
                                    <label>Approvazione necessaria</label>
                                    <select formControlName="require_approval" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Attività consentite su questo palco</label>
                                <div class="select2-info">
                                    <select [attr.disabled]="f.type.value != 2 ? 'disabled' : null" id="professions"
                                        formControlName="professions" class="form-control"
                                        data-dropdown-css-class="select2-info" data-placeholder="Scegli uno o più ...."
                                        multiple style="width: 100%;">
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Palchi connessi</label>
                                <div class="select2-info">
                                    <select id="places_connections" formControlName="places_connections"
                                        class="form-control" data-dropdown-css-class="select2-info"
                                        data-placeholder="Scegli uno o più ...." multiple style="width: 100%;">
                                    </select>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label>Testo aggiuntivo nella mail di conferma prenotazione</label>
                                <textarea id="accept_email_extra" formControlName="accept_email_extra" rows="10"
                                    [ngClass]="{ 'is-invalid': submitted && f.accept_email_extra.errors }"></textarea>
                                <div *ngIf="f.accept_email_extra.errors?.required" class="invalid-feedback">Campo
                                    obbligatorio</div>
                            </div>

                            <div class="text-center">
                                <ui-switch checkedLabel="ABILITATO" uncheckedLabel="NON ABILITATO"
                                    formControlName="enabled"></ui-switch>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button [disabled]="this.auth.currentUserValue.role_id == 1" type="submit"
                                class="btn btn-info" (click)="savePlace()"> <span *ngIf="isLoading"
                                    class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>
                        </div>
                    </div>
                </form>

                <div *ngIf="id != null && f.type.value != 3" class="card card-info mt-5">
                    <div class="card-header text-uppercase">
                        Date disponibili
                    </div>

                    <div class="card-body table-responsive">
                        <div class="mb-3">
                            <button *ngIf="checkedItems.length == 0" class="btn btn-info"
                                (click)="openModalAddDate()"><i class="fas fa-plus mr-1"></i>
                                Aggiungi data</button>

                            <button *ngIf="checkedItems.length == 0 && place?.dates.length > 0" class="btn btn-danger"
                                (click)="openModalDeleteDate()"><i class="fas fa-trash mr-1"></i>
                                Rimuovi date</button>

                            <button [swal]="deleteMultipleSwal" *ngIf="checkedItems.length > 0"
                                class="btn btn-danger">Elimina selezionati
                                ({{checkedItems.length}})</button>
                            <swal #deleteMultipleSwal title="Elimina"
                                text="Vuoi eliminare queste date ? Verranno eliminati solo gli slots non occupati"
                                icon="warning"
                                [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                (confirm)="deleteSelectedDates()"></swal>
                        </div>

                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                            class="table table-striped dtr-inline">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="icheck-info">
                                            <input type="checkbox" id="cb-all" (click)="checkuncheckall()">
                                            <label for="cb-all">
                                            </label>
                                        </div>
                                    </th>
                                    <th>Giorno</th>
                                    <th>Orari</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of place?.dates">
                                    <td class="table-id">
                                        <div class="icheck-info">
                                            <input type="checkbox" name="cb-item" [id]="item.date" [checked]="isChecked"
                                                [value]="item.date" (change)="itemSelected($event)">
                                            <label [for]="item.date">
                                            </label>
                                        </div>
                                    </td>
                                    <td>{{getFormatDate(item.date)}}</td>
                                    <td [innerHTML]=getSlots(item.slots)></td>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="editDate(item)"><i
                                                class="fas fa-pencil-alt mr-1"></i> Modifica</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questa data ?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deleteDate(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    </div>

</section>